import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './IconYoutube.styles'

interface Props extends PropsWithClassName {}

const IconYoutube: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'icon-youtube-root'}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1758 7.64453C20.9648 6.80078 20.2969 6.13281 19.4883 5.92188C17.9766 5.5 12 5.5 12 5.5C12 5.5 5.98828 5.5 4.47656 5.92188C3.66797 6.13281 3 6.80078 2.78906 7.64453C2.36719 9.12109 2.36719 12.2852 2.36719 12.2852C2.36719 12.2852 2.36719 15.4141 2.78906 16.9258C3 17.7695 3.66797 18.4023 4.47656 18.6133C5.98828 19 12 19 12 19C12 19 17.9766 19 19.4883 18.6133C20.2969 18.4023 20.9648 17.7695 21.1758 16.9258C21.5977 15.4141 21.5977 12.2852 21.5977 12.2852C21.5977 12.2852 21.5977 9.12109 21.1758 7.64453ZM10.0312 15.1328V9.4375L15.0234 12.2852L10.0312 15.1328Z"
        fill="white"
      />
    </Root>
  )
}

export { IconYoutube }
