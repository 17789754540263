import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './IconLinkedin.styles'

interface Props extends PropsWithClassName {}

const IconLinkedin: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'icon-linkedin-root'}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.92969 18.1087H6.20508V9.3489H8.92969V18.1087ZM7.55273 8.17702C6.70312 8.17702 6 7.4446 6 6.56569C6 5.36452 7.28906 4.6028 8.34375 5.21804C8.8418 5.48171 9.13477 6.00905 9.13477 6.56569C9.13477 7.4446 8.43164 8.17702 7.55273 8.17702ZM16.4004 18.1087V13.8606C16.4004 12.8352 16.3711 11.5462 14.9648 11.5462C13.5586 11.5462 13.3535 12.6301 13.3535 13.7727V18.1087H10.6289V9.3489H13.2363V10.5501H13.2656C13.6465 9.87624 14.5254 9.14382 15.8438 9.14382C18.5977 9.14382 19.125 10.9602 19.125 13.304V18.1087H16.4004Z"
        fill="white"
      />
    </Root>
  )
}

export { IconLinkedin }
