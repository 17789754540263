import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './IconZoom.styles'

interface Props extends PropsWithClassName {}

const IconZoom: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'icon-zoom-root'}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6875 9.75C13.6875 9.53906 13.4766 9.32812 13.2656 9.32812H11.2969V7.35938C11.2969 7.14844 11.0859 6.9375 10.875 6.9375H9.75C9.50391 6.9375 9.32812 7.14844 9.32812 7.35938V9.32812H7.35938C7.11328 9.32812 6.9375 9.53906 6.9375 9.75V10.875C6.9375 11.1211 7.11328 11.2969 7.35938 11.2969H9.32812V13.2656C9.32812 13.5117 9.50391 13.6875 9.75 13.6875H10.875C11.0859 13.6875 11.2969 13.5117 11.2969 13.2656V11.2969H13.2656C13.4766 11.2969 13.6875 11.1211 13.6875 10.875V9.75ZM20.7539 19.7695C21.0703 19.4531 21.0703 18.9258 20.7539 18.5742L17.2383 15.0586C17.0625 14.918 16.8516 14.8125 16.6406 14.8125H16.0781C17.0273 13.582 17.625 12.0352 17.625 10.3125C17.625 6.30469 14.3203 3 10.3125 3C6.26953 3 3 6.30469 3 10.3125C3 14.3555 6.26953 17.625 10.3125 17.625C12 17.625 13.5469 17.0625 14.8125 16.0781V16.6758C14.8125 16.8867 14.8828 17.0977 15.0586 17.2734L18.5391 20.7539C18.8906 21.1055 19.418 21.1055 19.7344 20.7539L20.7539 19.7695ZM15.0938 10.3125C15.0938 12.9844 12.9492 15.0938 10.3125 15.0938C7.64062 15.0938 5.53125 12.9844 5.53125 10.3125C5.53125 7.67578 7.64062 5.53125 10.3125 5.53125C12.9492 5.53125 15.0938 7.67578 15.0938 10.3125Z"
        fill="white"
      />
    </Root>
  )
}

export { IconZoom }
