import React from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './Row.styles'

interface Props extends PropsWithClassName {
  xsStart?: boolean
  xsCenter?: boolean
  xsEnd?: boolean
  xsAround?: boolean
  xsBetween?: boolean
  xsTop?: boolean
  xsMiddle?: boolean
  xsBottom?: boolean

  smStart?: boolean
  smCenter?: boolean
  smEnd?: boolean
  smAround?: boolean
  smBetween?: boolean
  smTop?: boolean
  smMiddle?: boolean
  smBottom?: boolean

  mdStart?: boolean
  mdCenter?: boolean
  mdEnd?: boolean
  mdAround?: boolean
  mdBetween?: boolean
  mdTop?: boolean
  mdMiddle?: boolean
  mdBottom?: boolean

  lgStart?: boolean
  lgCenter?: boolean
  lgEnd?: boolean
  lgAround?: boolean
  lgBetween?: boolean
  lgTop?: boolean
  lgMiddle?: boolean
  lgBottom?: boolean

  children?: JSX.Element[] | JSX.Element
}

const Row = (props: Props): React.ReactElement => {
  const {
    xsStart,
    xsCenter,
    xsEnd,
    xsAround,
    xsBetween,
    xsTop,
    xsMiddle,
    xsBottom,

    smStart,
    smCenter,
    smEnd,
    smAround,
    smBetween,
    smTop,
    smMiddle,
    smBottom,

    mdStart,
    mdCenter,
    mdEnd,
    mdAround,
    mdBetween,
    mdTop,
    mdMiddle,
    mdBottom,

    lgStart,
    lgCenter,
    lgEnd,
    lgAround,
    lgBetween,
    lgTop,
    lgMiddle,
    lgBottom,

    children,
    dataTestId,
  } = props
  function renderClassNameString(): string {
    let classNameString = ''

    // add classNames for xs breakpoint
    if (xsStart) {
      classNameString += ' start-xs'
    }
    if (xsCenter) {
      classNameString += ' center-xs'
    }
    if (xsEnd) {
      classNameString += ' end-xs'
    }
    if (xsAround) {
      classNameString += ' around-xs'
    }
    if (xsBetween) {
      classNameString += ' between-xs'
    }
    if (xsTop) {
      classNameString += ' top-xs'
    }
    if (xsMiddle) {
      classNameString += ' middle-xs'
    }
    if (xsBottom) {
      classNameString += ' bottom-xs'
    }

    // add classNames for sm breakpoints
    if (smStart) {
      classNameString += ' start-sm'
    }
    if (smCenter) {
      classNameString += ' center-sm'
    }
    if (smEnd) {
      classNameString += ' end-sm'
    }
    if (smAround) {
      classNameString += ' around-sm'
    }
    if (smBetween) {
      classNameString += ' between-sm'
    }
    if (smTop) {
      classNameString += ' top-sm'
    }
    if (smMiddle) {
      classNameString += ' middle-sm'
    }
    if (smBottom) {
      classNameString += ' bottom-sm'
    }

    // add classNames for md breakpoints
    if (mdStart) {
      classNameString += ' start-md'
    }
    if (mdCenter) {
      classNameString += ' center-md'
    }
    if (mdEnd) {
      classNameString += ' end-md'
    }
    if (mdAround) {
      classNameString += ' around-md'
    }
    if (mdBetween) {
      classNameString += ' between-md'
    }
    if (mdTop) {
      classNameString += ' top-md'
    }
    if (mdMiddle) {
      classNameString += ' middle-md'
    }
    if (mdBottom) {
      classNameString += ' bottom-md'
    }

    // add classNames for lg breakpoints
    if (lgStart) {
      classNameString += ' start-lg'
    }
    if (lgCenter) {
      classNameString += ' center-lg'
    }
    if (lgEnd) {
      classNameString += ' end-lg'
    }
    if (lgAround) {
      classNameString += ' around-lg'
    }
    if (lgBetween) {
      classNameString += ' between-lg'
    }
    if (lgTop) {
      classNameString += ' top-lg'
    }
    if (lgMiddle) {
      classNameString += ' middle-lg'
    }
    if (lgBottom) {
      classNameString += ' bottom-lg'
    }

    return classNameString
  }
  return (
    <Root
      className={(props.className || '') + ' row ' + renderClassNameString()}
      data-testid={dataTestId ? dataTestId : 'Row-root'}
    >
      {children}
    </Root>
  )
}

export { Row }
