import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './IconClose.styles'

interface Props extends PropsWithClassName {}

const IconClose: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'icon-close-root'}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Schließen</title>
      <desc>Schaltfläche, um Element zu schließen</desc>
      <path
        d="M14.5342 12.1787L18.0498 8.66309C18.5068 8.24121 18.5068 7.53809 18.0498 7.11621L17.2764 6.34277C16.8545 5.88574 16.1514 5.88574 15.7295 6.34277L12.2139 9.8584L8.66309 6.34277C8.24121 5.88574 7.53809 5.88574 7.11621 6.34277L6.34277 7.11621C5.88574 7.53809 5.88574 8.24121 6.34277 8.66309L9.8584 12.1787L6.34277 15.7295C5.88574 16.1514 5.88574 16.8545 6.34277 17.2764L7.11621 18.0498C7.53809 18.5068 8.24121 18.5068 8.66309 18.0498L12.2139 14.5342L15.7295 18.0498C16.1514 18.5068 16.8545 18.5068 17.2764 18.0498L18.0498 17.2764C18.5068 16.8545 18.5068 16.1514 18.0498 15.7295L14.5342 12.1787Z"
        fill="white"
      />
    </Root>
  )
}

export { IconClose }
