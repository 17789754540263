import React from 'react'

import Col from '@Components/atoms/Col'
import Container from '@Components/atoms/Container'
import LogoOpen from '@Components/atoms/Logos/LogoOpen'
import Row from '@Components/atoms/Row'
import { FontSizeText, FontWeightText } from '@Components/atoms/Text/Text'
import { PropsWithClassName } from '@Components/helper'
import { Routes } from '@Components/Routes'
import { Social } from '@Components/Social'

import {
  BottomLink,
  CenteredCol,
  FooterBar,
  FooterNavigation,
  List,
  ListItem,
  Nav,
  RightCol,
  Root,
  SocialLinks,
  StyledBackgroundSectionTeaser,
  StyledIconBrandFacebook,
  StyledIconBrandInstagram,
  StyledIconBrandLinkedin,
  StyledIconBrandTwitter,
  StyledIconBrandXing,
  StyledIconBrandYoutube,
  StyledLink,
  StyledLinkLogo,
  StyledLogo,
  StyledLogoText,
  StyledRow,
  StyledSocialLink,
  StyledText,
} from './Footer.styles'

interface Props extends PropsWithClassName {
  extraContent?: JSX.Element | React.ReactNode
}

const Footer = (props: Props): React.ReactElement => {
  const { extraContent } = props
  return (
    <Root className={props.className} data-testid={'Footer-root'}>
      {extraContent}
      <Container fluid>
        <Container>
          <StyledRow>
            <Nav xs={12} sm={3}>
              <FooterNavigation>
                <List>
                  <ListItem>
                    <StyledLink
                      data-testid="navigation-footer-jobs"
                      href={Routes.jobs}
                      passHref
                    >
                      Jobs
                    </StyledLink>
                  </ListItem>
                  <ListItem>
                    <StyledLink
                      data-testid="navigation-footer-services"
                      href={Routes.services}
                      passHref
                    >
                      Services
                    </StyledLink>
                  </ListItem>
                  <ListItem>
                    <StyledLink
                      data-testid="navigation-footer-qossmic"
                      href={Routes.qossmic}
                      passHref
                    >
                      QOSSMIC
                    </StyledLink>
                  </ListItem>
                </List>
                <List>
                  <ListItem>
                    <StyledLink
                      data-testid="navigation-footer-academy"
                      href={Routes.academy}
                      passHref
                    >
                      Academy
                    </StyledLink>
                  </ListItem>
                  <ListItem>
                    <StyledLink
                      data-testid="navigation-footer-community"
                      href={Routes.community}
                      passHref
                    >
                      Community
                    </StyledLink>
                  </ListItem>
                  <ListItem>
                    <StyledLink
                      data-testid="navigation-footer-contact"
                      href={Routes.contact}
                      passHref
                    >
                      Kontakt
                    </StyledLink>
                  </ListItem>
                </List>
              </FooterNavigation>
            </Nav>
            <CenteredCol xs={12} sm={6}>
              <StyledLinkLogo passHref href={Routes.home} title="QOSSMIC">
                <StyledLogo />
                <StyledLogoText />
                <LogoOpen color="dark" />
              </StyledLinkLogo>
            </CenteredCol>
            <RightCol xs={12} sm={3}>
              <StyledText
                size={FontSizeText.medium}
                weight={FontWeightText.normal}
              >
                Besuchen und folgen Sie uns auch auf unseren weiteren Kanälen
              </StyledText>
              <SocialLinks>
                <StyledSocialLink
                  passHref
                  href={Social.twitter}
                  target={'_blank'}
                  title="Twitter"
                >
                  <StyledIconBrandTwitter />
                </StyledSocialLink>
                <StyledSocialLink
                  passHref
                  href={Social.facebook}
                  target={'_blank'}
                  title="Facebook"
                >
                  <StyledIconBrandFacebook />
                </StyledSocialLink>
                <StyledSocialLink
                  passHref
                  href={Social.instagram}
                  target={'_blank'}
                  title="Instagram"
                >
                  <StyledIconBrandInstagram />
                </StyledSocialLink>
                <StyledSocialLink
                  passHref
                  href={Social.youtube}
                  target={'_blank'}
                  title="YouTube"
                >
                  <StyledIconBrandYoutube />
                </StyledSocialLink>
                <StyledSocialLink
                  passHref
                  href={Social.linkedin}
                  target={'_blank'}
                  title="LinkedIn"
                >
                  <StyledIconBrandLinkedin />
                </StyledSocialLink>
                <StyledSocialLink
                  passHref
                  href={Social.xing}
                  target={'_blank'}
                  title="Xing"
                >
                  <StyledIconBrandXing />
                </StyledSocialLink>
              </SocialLinks>
            </RightCol>
          </StyledRow>
        </Container>
        <FooterBar>
          <Row>
            <Col xs={12}>
              <BottomLink
                href={Routes.imprint}
                passHref
                className="bottom"
                data-testid="navigation-footer-imprint"
              >
                Impressum
              </BottomLink>
              <BottomLink
                href={Routes.privacy}
                passHref
                className="bottom"
                data-testid="navigation-footer-privacy"
              >
                Datenschutzerklärung
              </BottomLink>
            </Col>
          </Row>
        </FooterBar>
        <StyledBackgroundSectionTeaser />
      </Container>
    </Root>
  )
}

export { Footer }
