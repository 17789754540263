import Head from 'next/head'
import React, { useEffect, useState } from 'react'

import Burger from '@Components/atoms/Burger'
import { PropsWithClassName } from '@Components/helper'
import ContentPageHeadElement from '@Components/molecules/ContentPageHeadElement'
import CookieButton from '@Components/molecules/CookieButton'
import MobileNavigation from '@Components/molecules/MobileNavigation'
import Footer from '@Components/organisms/Footer'
import MetaData from '@Components/organisms/MetaData'
import {
  defaultMetaData,
  MetaDataInfo,
} from '@Components/organisms/MetaData/MetaData'
import { Routes } from '@Components/Routes'

import {
  Bar,
  Content,
  Main,
  Root,
  StyledLink,
  StyledLogo,
  StyledLogoOpen,
  StyledNavigationMain,
} from './DefaultTemplate.styles'

export enum PageBackground {
  white = 'white',
  bluedeep = 'bluedeep',
}

interface Props extends PropsWithClassName {
  transparentBar: boolean
  topContent?: JSX.Element | React.ReactNode
  footerContent?: JSX.Element | React.ReactNode
  background?: PageBackground
  metaData?: MetaDataInfo
  eyebrow?: string
  link?: Routes
  additionalMetaTags?: JSX.Element
}

const DefaultTemplate = (props: Props): React.ReactElement => {
  const {
    dataTestId,
    transparentBar,
    topContent,
    footerContent,
    background,
    metaData,
    eyebrow,
    link,
    additionalMetaTags,
  } = props
  const [mobileNav, setMobileNav] = useState<boolean>(false)
  const [headerVisible, setHeaderVisible] = useState<boolean>(true)
  const [barVisible, setBarVisible] = useState<boolean>(!transparentBar)
  const [lastScrollPos, setLastScrollPos] = useState<number>(0)

  const toggleNavigation = () => {
    setMobileNav(!mobileNav)
  }

  useEffect(() => {
    if (mobileNav) {
      document.documentElement.style.overflowY = 'hidden'
    } else {
      document.documentElement.style.overflowY = 'scroll'
    }

    const handleScroll = () => {
      const scrollY = window.scrollY
      const winH = window.innerHeight

      if (scrollY < lastScrollPos) {
        setHeaderVisible(true)
      } else {
        if (scrollY <= 90 || mobileNav) {
          setHeaderVisible(true)
        } else {
          setHeaderVisible(false)
        }
      }

      if (transparentBar) {
        if (scrollY > winH) {
          setBarVisible(true)
        } else {
          setBarVisible(false)
        }
      } else {
        setBarVisible(true)
      }
      setLastScrollPos(scrollY)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [lastScrollPos, barVisible, headerVisible, transparentBar, mobileNav])

  const showHeader = headerVisible
  const showBar = headerVisible && barVisible && !mobileNav

  return (
    <Root
      background={background}
      className={props.className}
      data-testid={'DefaultTemplate-root'}
    >
      <Head>
        <title>
          {metaData && metaData.title ? metaData.title : defaultMetaData.title}
        </title>
        {additionalMetaTags}
      </Head>
      <MetaData {...metaData} />
      <StyledLink
        href={Routes.home}
        passHref
        visible={showHeader}
        sticky={true}
        data-testid="LogoLink"
      >
        <StyledLogo data-testid="Logo" />
        <StyledLogoOpen color="light" />
      </StyledLink>
      <StyledNavigationMain visible={showHeader} sticky={true} />
      <Burger
        visible={showHeader}
        toggle={() => toggleNavigation()}
        clicked={mobileNav}
      />
      <Main data-testid={dataTestId}>
        {eyebrow && <ContentPageHeadElement link={link} eyebrow={eyebrow} />}
        <Content>{topContent}</Content>
      </Main>
      <CookieButton />
      <Footer extraContent={footerContent} />
      <MobileNavigation visible={mobileNav} close={() => setMobileNav(false)} />
      <Bar visible={showBar} />
    </Root>
  )
}

export { DefaultTemplate }
