import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './IconInstagram.styles'

interface Props extends PropsWithClassName {}

const IconInstagram: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'icon-instagram-root'}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5771 8.17871C9.70215 8.17871 8.20801 9.70215 8.20801 11.5479C8.20801 13.4229 9.70215 14.917 11.5771 14.917C13.4229 14.917 14.9463 13.4229 14.9463 11.5479C14.9463 9.70215 13.4229 8.17871 11.5771 8.17871ZM11.5771 13.7451C10.376 13.7451 9.37988 12.7783 9.37988 11.5479C9.37988 10.3467 10.3467 9.37988 11.5771 9.37988C12.7783 9.37988 13.7451 10.3467 13.7451 11.5479C13.7451 12.7783 12.7783 13.7451 11.5771 13.7451ZM15.8545 8.06152C15.8545 8.50098 15.5029 8.85254 15.0635 8.85254C14.624 8.85254 14.2725 8.50098 14.2725 8.06152C14.2725 7.62207 14.624 7.27051 15.0635 7.27051C15.5029 7.27051 15.8545 7.62207 15.8545 8.06152ZM18.0811 8.85254C18.0225 7.79785 17.7881 6.86035 17.0264 6.09863C16.2646 5.33691 15.3271 5.10254 14.2725 5.04395C13.1885 4.98535 9.93652 4.98535 8.85254 5.04395C7.79785 5.10254 6.88965 5.33691 6.09863 6.09863C5.33691 6.86035 5.10254 7.79785 5.04395 8.85254C4.98535 9.93652 4.98535 13.1885 5.04395 14.2725C5.10254 15.3271 5.33691 16.2354 6.09863 17.0264C6.88965 17.7881 7.79785 18.0225 8.85254 18.0811C9.93652 18.1396 13.1885 18.1396 14.2725 18.0811C15.3271 18.0225 16.2646 17.7881 17.0264 17.0264C17.7881 16.2354 18.0225 15.3271 18.0811 14.2725C18.1396 13.1885 18.1396 9.93652 18.0811 8.85254ZM16.6748 15.415C16.4697 16.001 16.001 16.4404 15.4443 16.6748C14.5654 17.0264 12.5146 16.9385 11.5771 16.9385C10.6104 16.9385 8.55957 17.0264 7.70996 16.6748C7.12402 16.4404 6.68457 16.001 6.4502 15.415C6.09863 14.5654 6.18652 12.5146 6.18652 11.5479C6.18652 10.6104 6.09863 8.55957 6.4502 7.68066C6.68457 7.12402 7.12402 6.68457 7.70996 6.4502C8.55957 6.09863 10.6104 6.18652 11.5771 6.18652C12.5146 6.18652 14.5654 6.09863 15.4443 6.4502C16.001 6.65527 16.4404 7.12402 16.6748 7.68066C17.0264 8.55957 16.9385 10.6104 16.9385 11.5479C16.9385 12.5146 17.0264 14.5654 16.6748 15.415Z"
        fill="white"
      />
    </Root>
  )
}

export { IconInstagram }
