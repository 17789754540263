import styled, { css, keyframes } from 'styled-components'

import { LoadingSpinnerSize } from '@Components/atoms/LoadingSpinner/LoadingSpinner'

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

interface RootProps {
  readonly invertColors: boolean
  readonly size: LoadingSpinnerSize
  readonly isPositionAbsolute: boolean
}

export const Root = styled.div<RootProps>`
  border-radius: 50%;
  margin: auto;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  transition: opacity 250ms;
  animation: ${rotate} 1s linear;
  animation-iteration-count: infinite;
  transition-delay: 250ms;

  border: ${(props) => {
    if (props.invertColors) {
      return '4px solid rgba(255, 255, 255, 0.5)'
    }
    return '6px solid rgba(37, 99, 235, 0.4)'
  }};

  border-top-color: ${(props) => {
    if (props.invertColors) {
      return props.theme.palette.default.white
    }
    return props.theme.palette.default.primary
  }};

  ${(props) => {
    if (props.isPositionAbsolute) {
      return css`
        position: absolute;
        left: 25%;
        right: 25%;
        top: 25%;
        bottom: 25%;
      `
    }

    return ''
  }}
`
