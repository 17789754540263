import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './IconPhone.styles'

interface Props extends PropsWithClassName {}

const IconPhone: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'icon-phone-root'}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3472 3.87081L16.691 3.02706C16.3042 2.92159 15.8824 3.13253 15.7066 3.51924L14.0191 7.45674C13.8785 7.80831 13.9839 8.19503 14.2652 8.44112L16.4097 10.1989C15.1441 12.8708 12.9292 15.1208 10.187 16.4216L8.42923 14.2771C8.18314 13.9958 7.79642 13.8903 7.44486 14.031L3.50736 15.7185C3.12064 15.8942 2.94486 16.3161 3.01517 16.7028L3.85892 20.3591C3.96439 20.7458 4.2808 20.9919 4.70267 20.9919C13.7027 20.9919 21.0152 13.7146 21.0152 4.6794C21.0152 4.29268 20.7339 3.97628 20.3472 3.87081Z"
        fill="white"
      />
    </Root>
  )
}

export { IconPhone }
