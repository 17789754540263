import React, { PropsWithChildren } from 'react'

import IconArrowLeft from '@Components/atoms/Icons/IconArrowLeft'
import { PropsWithClassName } from '@Components/helper'

import { NavigationLinks } from '../NavigationMain/NavigationMain'
import {
  Background,
  Links,
  Root,
  Styled2ndLevelLink,
  Styled3rdLevelLink,
  StyledHeaderWrapper,
  StyledLink,
  StyledLinkWrapper,
  StyledOverlay,
} from './MobileNavigation.styles'

interface Props extends PropsWithClassName {
  close: () => void
  visible: boolean
}

const SecondLevelModal: React.FC<
  PropsWithChildren<{
    visible: boolean
    closeNavigation: () => void
    back: () => void
    items: {
      name: string
      links: {
        href: string
        label: string
        isDisabled: boolean
        subNavs?: { href: string; label: string; isDisabled: boolean }[]
      }[]
    }
  }>
> = (props) => {
  return (
    <StyledOverlay visible={props.visible}>
      <Links>
        <StyledHeaderWrapper
          onClick={() => {
            props.back()
          }}
        >
          <IconArrowLeft />
          {props.items.name}
        </StyledHeaderWrapper>
        {props.items.links.map((link) => {
          return (
            <>
              <Styled2ndLevelLink
                key={link.label}
                href={link.href}
                passHref
                onClick={() => close()}
                disabled={link.isDisabled}
              >
                {link.label}
              </Styled2ndLevelLink>
              {link.subNavs?.map((subLink) => {
                return (
                  <Styled3rdLevelLink
                    key={subLink.label}
                    href={subLink.href}
                    passHref
                    onClick={() => close()}
                    disabled={subLink.isDisabled}
                  >
                    {subLink.label}
                  </Styled3rdLevelLink>
                )
              })}
            </>
          )
        })}
      </Links>
      <Background
        onClick={() => {
          props.back()
          props.closeNavigation()
        }}
      />
    </StyledOverlay>
  )
}
const MobileNavigation: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { close, visible } = props
  const [openSecondary, setOpenSecondary] = React.useState<boolean>(false)
  const [seconLevelLinks, setSeconLevelLinks] = React.useState<{
    name: string
    links: {
      href: string
      label: string
      isDisabled: boolean
      subNavs?: { href: string; label: string; isDisabled: boolean }[]
    }[]
  }>({ name: '', links: [] })
  return (
    <Root
      visible={visible}
      className={props.className}
      data-testid={'mobile-navigation-root'}
    >
      {seconLevelLinks.name && (
        <SecondLevelModal
          visible={openSecondary}
          back={() => {
            setOpenSecondary(false), setSeconLevelLinks({ name: '', links: [] })
          }}
          closeNavigation={() => {
            close(), setSeconLevelLinks({ name: '', links: [] })
          }}
          items={seconLevelLinks}
        />
      )}
      {visible && (
        <Links>
          {NavigationLinks.map((link) => {
            if (link.subNavs) {
              return (
                <StyledLinkWrapper
                  key={link.label}
                  onClick={() => {
                    setSeconLevelLinks({
                      name: link.label,
                      links: link.subNavs!,
                    }),
                      setOpenSecondary(true)
                  }}
                  visible={visible}
                >
                  {link.label}
                </StyledLinkWrapper>
              )
            } else {
              return (
                <StyledLink
                  key={link.label}
                  href={link.href}
                  passHref
                  onClick={() => close()}
                  visible={visible}
                >
                  {link.label}
                </StyledLink>
              )
            }
          })}
        </Links>
      )}
      <Background onClick={() => close()} />
    </Root>
  )
}

export { MobileNavigation }
