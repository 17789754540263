import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { BottomBun, Patty, Root, TopBun } from './Burger.styles'

interface Props extends PropsWithClassName {
  toggle: () => void
  clicked: boolean
  visible: boolean
}

const Burger: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { toggle, clicked, visible } = props
  return (
    <Root
      className={props.className}
      data-testid={'burger-root'}
      onClick={() => toggle()}
      visible={visible}
    >
      <TopBun clicked={clicked} />
      <Patty clicked={clicked} />
      <BottomBun clicked={clicked} />
    </Root>
  )
}

export { Burger }
