import React, { PropsWithChildren } from 'react'

import { ButtonModifier, ButtonType } from '@Components/atoms/Button/Button'
import Container from '@Components/atoms/Container'
import { FontSizeEyebrow } from '@Components/atoms/Eyebrow/Eyebrow'
import IconArrowLeft from '@Components/atoms/Icons/IconArrowLeft'
import Row from '@Components/atoms/Row'
import { PropsWithClassName } from '@Components/helper'
import { Routes } from '@Components/Routes'

import {
  Root,
  StyledBackgroundMask,
  StyledButton,
  StyledCol,
  StyledEyebrow,
} from './ContentPageHeadElement.styles'

interface Props extends PropsWithClassName {
  eyebrow?: string
  link?: Routes
}

const ContentPageHeadElement: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { eyebrow, link } = props
  return (
    <Root
      className={props.className}
      data-testid={'content-page-head-element-root'}
    >
      <Container fluid>
        <Container>
          <Row>
            <StyledCol xs={12} sm={12} md={12} lg={12}>
              {link && (
                <StyledButton
                  href={link ? link : '/'}
                  modifier={ButtonModifier.primary}
                  type={ButtonType.button}
                  icon={<IconArrowLeft />}
                />
              )}
              {eyebrow && (
                <StyledEyebrow styleAs={3} size={FontSizeEyebrow.normal}>
                  {eyebrow}
                </StyledEyebrow>
              )}
            </StyledCol>
          </Row>
        </Container>
        <StyledBackgroundMask
          className="content-page-head"
          top={-520}
          left={520}
        />
      </Container>
    </Root>
  )
}

export { ContentPageHeadElement }
