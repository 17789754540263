import Link from 'next/link'
import styled, { keyframes } from 'styled-components'

interface NavProps {
  visible: boolean
}

export const Root = styled.div<NavProps>`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, ${(props) => (props.visible ? '0.95' : '0')});
  width: 100%;
  height: 100%;
  z-index: 95;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${(props) =>
    props.visible ? 'translateY(0px)' : 'translateY(-100%)'};
  transition: all 400ms ease-in-out, opacity 400ms ease-in-out;
  @media screen and (min-width: 825px) {
    display: none;
  }
`

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

export const StyledLink = styled(Link)<NavProps>`
  font-size: 1.875rem;
  color: ${(props) => props.theme.palette.default.white};
  padding: ${(props) => props.theme.padding.mini} !important;
`

export const Styled2ndLevelLink = styled(Link)<{ disabled: boolean }>`
  font-size: 1.5rem;
  color: ${(props) => props.theme.palette.default.white};
  padding: ${(props) => props.theme.padding.mini} !important;
  ${(props) =>
    props.disabled &&
    `
    color: grey;
  `}
`
export const Styled3rdLevelLink = styled(Link)<{ disabled: boolean }>`
  font-size: 1.2rem;
  color: ${(props) => props.theme.palette.default.white};
  padding: ${(props) => props.theme.padding.mini} !important;
  ${(props) =>
    props.disabled &&
    `
    color: grey;
  `}
`

export const StyledLinkWrapper = styled.div<NavProps>`
  font-size: 1.875rem;
  color: ${(props) => props.theme.palette.default.white} !important;
  padding: ${(props) => props.theme.padding.mini};
  display: flex;
  align-items: center;
  font-feature-settings: 'salt' !important;
`
export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  width: 100%;
  height: 100%;
  z-index: 1;
`

export const StyledOverlay = styled.div<NavProps>`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0);
  width: 100%;
  height: 100%;
  z-index: 96;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 375px) {
    justify-content: start;
    padding-top: 25%;
  }
  @media screen and (min-width: 825px) {
    display: none;
  }
`

export const Links = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;

  div,
  a {
    opacity: 0;
  }

  div:nth-child(1),
  a:nth-child(1) {
    animation: ${fadeIn} 0.5s linear 400ms forwards;
  }

  div:nth-child(2),
  a:nth-child(2) {
    animation: ${fadeIn} 0.5s linear 500ms forwards;
  }

  div:nth-child(3),
  a:nth-child(3) {
    animation: ${fadeIn} 0.5s linear 600ms forwards;
  }

  div:nth-child(4),
  a:nth-child(4) {
    animation: ${fadeIn} 0.5s linear 700ms forwards;
  }

  div:nth-child(5),
  a:nth-child(5) {
    animation: ${fadeIn} 0.5s linear 800ms forwards;
  }

  div:nth-child(6),
  a:nth-child(6) {
    animation: ${fadeIn} 0.5s linear 900ms forwards;
  }

  div:nth-child(7),
  a:nth-child(7) {
    animation: ${fadeIn} 0.5s linear 1000ms forwards;
  }
`
export const StyledHeaderWrapper = styled.div`
  width: 100%;
  zindex: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.875rem;
  color: ${(props) => props.theme.palette.default.white};
  padding-bottom: 59px;

  svg {
    position: fixed;
    left: 0;
    margin-left: 40px;
  }
`
