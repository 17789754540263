import styled, { css } from 'styled-components'

export type BackgroundColors = 'light' | 'regular' | 'deep' | 'dark'

export interface ContainerBackground {
  background?: BackgroundColors
}

export const Root = styled.div<ContainerBackground>`
  ${(props) =>
    props.background === 'light' &&
    css`
      background: ${(props) => props.theme.palette.default.white};
    `}
  ${(props) =>
    props.background === 'regular' &&
    css`
      background: ${(props) => props.theme.palette.default.greyUltralight};
    `}
  ${(props) =>
    props.background === 'deep' &&
    css`
      background: ${(props) => props.theme.palette.default.blueDeep};
    `}
    ${(props) =>
    props.background === 'dark' &&
    css`
      background: ${(props) => props.theme.palette.default.blueDark};
    `}
`
