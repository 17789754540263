import Link from 'next/link'
import styled from 'styled-components'

import Button from '@Components/atoms/Button'
import { StyledButtonLink } from '@Components/atoms/Button/Button.styles'
import { FontSizeText, FontWeightText } from '@Components/atoms/Text/Text'
import { getTextStyles } from '@Components/atoms/Text/Text.styles'

// excludedProps is used to exclude props from being passed to the DOM (to avoid
// React warnings)
const excludedProps = ['active']

export const Root = styled.nav`
  font-family: ${(props) => props.theme.font.primaryFamily};
  display: none;
  position: fixed;
  top: 16px;
  right: 14px;
  z-index: 99;
  transition: all 400ms ease-in-out;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 825px) {
    display: flex;
  }

  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    right: 40px;
  }
`

interface StyledLinkProps {
  active: boolean
}

export const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !excludedProps.includes(prop),
})<StyledLinkProps>`
  ${getTextStyles({
    size: FontSizeText.medium,
    weight: FontWeightText.normal,
    bold: FontWeightText.bold,
  })}
  user-select: none;
  font-feature-settings: 'salt' !important;
  margin-bottom: 0;
  color: ${(props) => props.theme.palette.default.white};
  cursor: pointer;
  margin-right: 25px;
  padding-top: 8px;
  padding-bottom: 3px;
  border-bottom: 0.25rem solid
    ${(props) =>
      props.active ? props.theme.palette.default.primary : 'transparent'};
  transition: border 300ms ease-in-out;

  &:hover {
    border-bottom: 0.25rem solid
      ${(props) => props.theme.palette.default.primary};
  }

  &:active {
    border-bottom: 0.25rem solid ${(props) => props.theme.palette.default.white};
  }

  @media screen and (min-width: 825px) and (max-width: 1024px) {
    font-size: 16px;
    margin-right: 12px;
  }
  display: flex;
  align-items: center;
`

export const StyledButton = styled(Button)`
  ${StyledButtonLink} {
    margin: 0;
    padding: 4px 22px 0 22px;
    display: block;
    min-height: 41px;
  }
`

export const StyledDropDown = styled.div`
  display: none;
  background-color: ${(props) => props.theme.palette.default.black};
  z-index: 10;
  position: absolute;
  top: 41px;
  padding: 20px;
`

export const StyledDDContainer = styled.div`
  &:hover ${StyledLink} {
    border-bottom: 0.25rem solid
      ${(props) => props.theme.palette.default.primary};
  }

  &:hover ${StyledDropDown} {
    display: flex;
    flex-direction: column;
  }

  &:hover {
    svg {
      transition: rotate 300ms ease;
      rotate: 180deg;
    }
  }
`

export const StyledDDLink = styled.a<{ disabled: boolean }>`
  color: ${(props) => props.theme.palette.default.white};
  transition: color 100ms ease-in-out;

  &:hover {
    color: ${(props) => props.theme.palette.default.primary};
  }

  ${(props) =>
    props.disabled &&
    `
    color: ${props.theme.palette.default.greyMiddle};
    &:hover {
      color: ${props.theme.palette.default.greyMiddle};
    }
  `}
`
export const Styled3rdDDLink = styled.a<{ disabled: boolean }>`
  color: ${(props) => props.theme.palette.default.white};
  margin-left: 30px;
  padding-top: 12px;

  transition: color 100ms ease-in-out;

  font-size: 15px;

  &:hover {
    color: ${(props) => props.theme.palette.default.primary};
  }

  ${(props) =>
    props.disabled &&
    `
    color: ${props.theme.palette.default.greyMiddle};
    &:hover {
      color: ${props.theme.palette.default.greyMiddle};
    }
  `}
`
export const StyledHirachieWrapper = styled.div<{ showDivider: boolean }>`
  padding-right: 80px;
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.showDivider &&
    `
   
  `}
`
//   padding-top: 10px;
// padding-bottom: 10px;
// border-bottom: 1px solid ${props.theme.palette.default.greyMiddle};
