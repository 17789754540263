import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './IconPulldown.styles'

interface Props extends PropsWithClassName {}

const IconPulldown: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'icon-pulldown-root'}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0928 16.0615C11.4092 16.3779 11.9365 16.3779 12.2529 16.0615L19.1084 9.24121C19.4248 8.88965 19.4248 8.3623 19.1084 8.0459L18.2998 7.2373C17.9834 6.9209 17.4561 6.9209 17.1045 7.2373L11.6904 12.6514L6.24121 7.2373C5.88965 6.9209 5.3623 6.9209 5.0459 7.2373L4.2373 8.0459C3.9209 8.3623 3.9209 8.88965 4.2373 9.24121L11.0928 16.0615Z"
        fill="white"
      />
    </Root>
  )
}

export { IconPulldown }
