import styled from 'styled-components'

interface BurgerProps {
  visible: boolean
}
interface LayerProps {
  clicked: boolean
}

export const TopBun = styled.div<LayerProps>`
  background-color: ${(props) => props.theme.palette.default.white};
  height: 3px;
  width: 30px;
  position: absolute;
  top: ${(props) => (props.clicked ? '10px' : '0')};
  left: 0;
  transform-origin: 50% 50%;
  transform: rotate(${(props) => (props.clicked ? '45deg' : '0deg')});
  transition: all 200ms ease-in-out;
  border-radius: 3px;
`
export const Patty = styled.div<LayerProps>`
  background-color: ${(props) => props.theme.palette.default.white};
  height: 3px;
  width: 30px;
  position: absolute;
  top: 10px;
  left: 0;
  transform-origin: 50% 50%;
  opacity: ${(props) => (props.clicked ? '0' : '1')};
  transition: opacity 200ms ease-in-out;
  border-radius: 3px;
`
export const BottomBun = styled.div<LayerProps>`
  background-color: ${(props) => props.theme.palette.default.white};
  height: 3px;
  width: 30px;
  position: absolute;
  top: ${(props) => (props.clicked ? '10px' : '20px')};
  left: 0;
  transform-origin: 50% 50%;
  transform: rotate(${(props) => (props.clicked ? '-45deg' : '0deg')});
  transition: all 200ms ease-in-out;
  border-radius: 3px;
`

export const Root = styled.div<BurgerProps>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: fixed;
  top: 23px;
  right: 14px;
  width: 30px;
  height: 19px;
  z-index: 100;
  transform: ${(props) =>
    props.visible ? 'translateY(0px)' : 'translateY(-90px)'};
  transition: all 200ms ease-in-out;
  @media screen and (min-width: 825px) {
    display: none;
  }
`
