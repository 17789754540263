import React from 'react'

import { PropsWithClassName } from '@Components/helper'

import {
  TextIntro,
  TextMedium,
  TextMini,
  TextNormal,
  TextSmall,
} from './Text.styles'

export enum FontWeightText {
  normal = 350,
  bold = 600,
}

export enum FontSizeText {
  intro = 'intro',
  large = 'large',
  normal = 'normal',
  medium = 'medium',
  small = 'small',
  mini = 'mini',
}

interface Props extends PropsWithClassName {
  children: string | React.ReactNode
  weight?: FontWeightText
  size?: FontSizeText
  dataTestId?: string
  onClick?: () => void
}

const Text = (props: Props): React.ReactElement => {
  const { className, children, size, weight, dataTestId, onClick } = props

  function renderText(
    fontSize: FontSizeText,
    fontWeight: FontWeightText
  ): JSX.Element {
    switch (fontSize) {
      case FontSizeText.intro:
        return (
          <TextIntro
            size={fontSize}
            weight={fontWeight}
            bold={FontWeightText.bold}
            onClick={onClick}
            data-testid={dataTestId ? dataTestId : 'Text-root'}
            className={className}
          >
            {children}
          </TextIntro>
        )
      case FontSizeText.normal:
        return (
          <TextNormal
            size={fontSize}
            weight={fontWeight}
            bold={FontWeightText.bold}
            onClick={onClick}
            data-testid={dataTestId ? dataTestId : 'Text-root'}
            className={className}
          >
            {children}
          </TextNormal>
        )
      case FontSizeText.medium:
        return (
          <TextMedium
            size={fontSize}
            weight={fontWeight}
            bold={FontWeightText.bold}
            onClick={onClick}
            data-testid={dataTestId ? dataTestId : 'Text-root'}
            className={className}
          >
            {children}
          </TextMedium>
        )
      case FontSizeText.small:
        return (
          <TextSmall
            size={fontSize}
            weight={fontWeight}
            bold={FontWeightText.bold}
            onClick={onClick}
            data-testid={dataTestId ? dataTestId : 'Text-root'}
            className={className}
          >
            {children}
          </TextSmall>
        )
      case FontSizeText.mini:
        return (
          <TextMini
            size={fontSize}
            weight={fontWeight}
            bold={FontWeightText.bold}
            onClick={onClick}
            data-testid={dataTestId ? dataTestId : 'Text-root'}
            className={className}
          >
            {children}
          </TextMini>
        )

      default:
        return (
          <TextNormal
            size={fontSize}
            weight={FontWeightText.normal}
            bold={FontWeightText.bold}
            onClick={onClick}
            data-testid={dataTestId ? dataTestId : 'Text-root'}
            className={className}
          >
            {children}
          </TextNormal>
        )
    }
  }

  return renderText(
    size ? size : FontSizeText.normal,
    weight ? weight : FontWeightText.normal
  )
}

export { Text }
