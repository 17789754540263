import Link from 'next/link'
import styled, { css } from 'styled-components'

import { ButtonModifier } from '@Components/atoms/Button/Button'
import LoadingSpinner from '@Components/atoms/LoadingSpinner'

import { defaultTheme } from '@Assets/styles/theme/theme'

import { FontSizeText, FontWeightText } from '../Text/Text'
import { getTextStyles } from '../Text/Text.styles'

interface ButtonProps {
  readonly modifier: ButtonModifier
  readonly $loading: boolean
  readonly icon: boolean
  readonly noText: boolean
}

// excludedProps is used to exclude props from being passed to the DOM (to avoid
// React warnings)
const excludedProps = ['noText', 'icon']

const handleBackgroundColor = (color: string) => {
  switch (color) {
    case ButtonModifier.primary:
      return defaultTheme.palette.button.primary.background
    case ButtonModifier.secondary:
      return 'transparent'
    case ButtonModifier.tertiary:
      return 'transparent'
    default:
      return defaultTheme.palette.button.primary.background
  }
}
const handleBackgroundColorHover = (color: string) => {
  switch (color) {
    case ButtonModifier.primary:
      return defaultTheme.palette.default.primaryDark
    case ButtonModifier.secondary:
      return 'transparent'
    case ButtonModifier.tertiary:
      return 'transparent'
    default:
      return defaultTheme.palette.default.primaryDark
  }
}
const handleTextColor = (color: string) => {
  switch (color) {
    case ButtonModifier.primary:
      return defaultTheme.palette.button.primary.text
    case ButtonModifier.secondary:
      return defaultTheme.palette.default.primary
    case ButtonModifier.tertiary:
      return defaultTheme.palette.default.greyMiddle
    default:
      return defaultTheme.palette.default.white
  }
}
const handleTextColorHover = (color: string) => {
  switch (color) {
    case ButtonModifier.primary:
      return defaultTheme.palette.default.white
    case ButtonModifier.secondary:
      return defaultTheme.palette.default.primaryDark
    case ButtonModifier.tertiary:
      return defaultTheme.palette.default.greyMiddle
    default:
      return defaultTheme.palette.default.white
  }
}

const handleBorderColor = (color: string) => {
  switch (color) {
    case ButtonModifier.primary:
      return defaultTheme.palette.default.primary
    case ButtonModifier.secondary:
      return defaultTheme.palette.default.primary
    case ButtonModifier.tertiary:
      return 'transparent'
    default:
      return defaultTheme.palette.default.white
  }
}

const handleBorderColorHover = (color: string) => {
  switch (color) {
    case ButtonModifier.primary:
      return defaultTheme.palette.default.primaryDark
    case ButtonModifier.secondary:
      return defaultTheme.palette.default.primaryDark
    case ButtonModifier.tertiary:
      return 'transparent'
    default:
      return defaultTheme.palette.default.white
  }
}
const handleIconColor = (color: string) => {
  switch (color) {
    case ButtonModifier.primary:
      return defaultTheme.palette.default.white
    case ButtonModifier.secondary:
      return defaultTheme.palette.default.primary
    case ButtonModifier.tertiary:
      return defaultTheme.palette.default.primary
    default:
      return defaultTheme.palette.default.white
  }
}
const handleIconColorHover = (color: string) => {
  switch (color) {
    case ButtonModifier.primary:
      return defaultTheme.palette.default.primary
    case ButtonModifier.secondary:
      return defaultTheme.palette.default.primaryDark
    case ButtonModifier.tertiary:
      return defaultTheme.palette.default.primary
    default:
      return defaultTheme.palette.default.white
  }
}

export const Root = styled.div`
  user-select: none;
`

export const sharedCss = (sharedCssProps: ButtonProps) => {
  return css`
    cursor: pointer;
    display: inline-flex;
    column-gap: ${(props) => props.theme.margin.small};
    position: relative;
    font-family: ${(props) => props.theme.font.primaryFamily};
    flex-direction: row;
    text-align: center;
    align-items: center;
    padding: ${() =>
      sharedCssProps.icon && !sharedCssProps.noText ? '10px' : '10px 22px'};
    min-width: 49px;
    min-height: 49px;
    white-space: nowrap;
    background-color: ${() => handleBackgroundColor(sharedCssProps.modifier)};
    border: 2px solid ${() => handleBorderColor(sharedCssProps.modifier)};
    border-radius: ${(props) =>
      sharedCssProps.icon && !sharedCssProps.noText
        ? props.theme.borderRadius.icon
        : props.theme.borderRadius.button};
    ${getTextStyles({
      size: FontSizeText.medium,
      weight: FontWeightText.normal,
      bold: FontWeightText.bold,
    })}
    color: ${() => handleTextColor(sharedCssProps.modifier)};
    a {
      color: ${() => handleTextColor(sharedCssProps.modifier)};
      &:visited {
        color: ${() => handleTextColor(sharedCssProps.modifier)};
      }
    }
    svg path {
      fill: ${() => handleIconColor(sharedCssProps.modifier)};
    }
    &:disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
    &:hover {
      outline: none;
      background-color: ${() =>
        handleBackgroundColorHover(sharedCssProps.modifier)};
      border: 2px solid ${() => handleBorderColorHover(sharedCssProps.modifier)};
      color: ${() => handleTextColorHover(sharedCssProps.modifier)};
      svg path {
        fill: ${() => handleIconColorHover(sharedCssProps.modifier)};
      }
    }
    &:focus {
      outline: none;
    }
  `
}

export const StyledButtonLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !excludedProps.includes(prop),
})<ButtonProps>`
  ${(props) => sharedCss(props)}
`

export const RealButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !excludedProps.includes(prop),
})<ButtonProps>`
  ${(props) => sharedCss(props)}
`

export const ButtonIcon = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
`

export const StyledLoadingSpinner = styled(LoadingSpinner)`
  position: relative;
  width: 24px;
  height: 24px;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
`
