import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { HeadlineImportances } from '../Headline/Headline'
import {
  EyebrowH1,
  EyebrowH2,
  EyebrowH3,
  EyebrowH4,
  EyebrowH5,
} from './Eyebrow.styles'

export enum FontSizeEyebrow {
  normal = 'normal',
  small = 'small',
}

interface Props extends PropsWithClassName {
  children: string | React.ReactNode
  size?: FontSizeEyebrow
  styleAs: HeadlineImportances
}

const Eyebrow: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { className, children, size, styleAs } = props

  const renderEyebrow = () => {
    switch (styleAs) {
      case HeadlineImportances.h1:
        return (
          <EyebrowH1
            className={className}
            data-testid={'Eyebrow-root'}
            size={size ? size : FontSizeEyebrow.normal}
          >
            {children}
          </EyebrowH1>
        )

      case HeadlineImportances.h2:
        return (
          <EyebrowH2
            className={className}
            data-testid={'Eyebrow-root'}
            size={size ? size : FontSizeEyebrow.normal}
          >
            {children}
          </EyebrowH2>
        )

      case HeadlineImportances.h3:
        return (
          <EyebrowH3
            className={className}
            data-testid={'Eyebrow-root'}
            size={size ? size : FontSizeEyebrow.normal}
          >
            {children}
          </EyebrowH3>
        )

      case HeadlineImportances.h4:
        return (
          <EyebrowH4
            className={className}
            data-testid={'Eyebrow-root'}
            size={size ? size : FontSizeEyebrow.normal}
          >
            {children}
          </EyebrowH4>
        )

      case HeadlineImportances.h5:
        return (
          <EyebrowH5
            className={className}
            data-testid={'Eyebrow-root'}
            size={size ? size : FontSizeEyebrow.normal}
          >
            {children}
          </EyebrowH5>
        )

      default:
        return (
          <EyebrowH2
            className={className}
            data-testid={'Eyebrow-root'}
            size={size ? size : FontSizeEyebrow.normal}
          >
            {children}
          </EyebrowH2>
        )
    }
  }
  return renderEyebrow()
}

export { Eyebrow }
