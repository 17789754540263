import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './IconLinkExternal.styles'

interface Props extends PropsWithClassName {}

const IconLinkExternal: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'icon-link-external-root'}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1875 14.25H17.0625C16.7461 14.25 16.5 14.5312 16.5 14.8125V18.75H5.25V7.5H10.3125C10.5938 7.5 10.875 7.25391 10.875 6.9375V5.8125C10.875 5.53125 10.5938 5.25 10.3125 5.25H4.6875C3.73828 5.25 3 6.02344 3 6.9375V19.3125C3 20.2617 3.73828 21 4.6875 21H17.0625C17.9766 21 18.75 20.2617 18.75 19.3125V14.8125C18.75 14.5312 18.4688 14.25 18.1875 14.25ZM19.6641 3H14.1094C13.5469 3 13.125 3.45703 13.125 3.98438V4.65234C13.125 5.17969 13.5469 5.60156 14.0742 5.60156C14.1094 5.60156 14.1094 5.60156 14.1094 5.60156L16.5 5.53125L7.74609 14.25C7.57031 14.3906 7.46484 14.6016 7.46484 14.8477C7.46484 15.0586 7.57031 15.2695 7.74609 15.4453L8.55469 16.2539H8.58984C8.73047 16.4297 8.94141 16.5 9.15234 16.5C9.39844 16.5 9.60938 16.4297 9.75 16.2539L18.4688 7.5L18.3984 9.89062C18.3984 10.4531 18.8203 10.875 19.3828 10.875H20.0156C20.543 10.875 21 10.4531 21 9.89062V4.33594C21 3.59766 20.4023 3 19.6641 3Z"
        fill="white"
      />
    </Root>
  )
}

export { IconLinkExternal }
