import styled, { css } from 'styled-components'

import { FontSizeText, FontWeightText } from './Text'

interface TextProps {
  weight: FontWeightText | string
  bold: FontWeightText | string
  size: FontSizeText | string
}

export const TextIntroStyles = (props: TextProps) => {
  const { weight, bold } = props
  return css`
    color: ${(props) => props.theme.palette.default.greyMiddle};
    -webkit-font-smoothing: antialiased;
    word-wrap: break-word;
    margin-bottom: ${(props) => props.theme.margin.default};
    font-family: ${(props) => props.theme.font.primaryFamily};
    font-size: ${(props) => props.theme.font.text.normal.intro.mobile.fontSize};
    line-height: ${(props) =>
      props.theme.font.text.normal.intro.mobile.lineHeight};
    font-weight: ${() => weight};
    letter-spacing: ${(props) =>
      props.theme.font.text.normal.intro.mobile.letterSpacing};
    a {
      text-decoration: underline;
      &:link {
        color: ${(props) => props.theme.palette.default.greyMiddle};
      }
      &:active {
        color: ${(props) => props.theme.palette.default.greyMiddle};
      }
      &:visited {
        color: ${(props) => props.theme.palette.default.greyMiddle};
      }
    }
    b,
    strong {
      font-weight: ${() => bold};
    }
    @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
      font-size: ${(props) =>
        props.theme.font.text.normal.intro.desktop.fontSize};
      line-height: ${(props) =>
        props.theme.font.text.normal.intro.desktop.lineHeight};
      font-weight: ${() => weight};
      letter-spacing: ${(props) =>
        props.theme.font.text.normal.intro.desktop.letterSpacing};
    }
  `
}

export const TextNormalStyles = (props: TextProps) => {
  const { weight, bold } = props
  return css`
    color: ${(props) => props.theme.palette.default.greyMiddle};
    -webkit-font-smoothing: antialiased;
    word-wrap: break-word;
    margin-bottom: ${(props) => props.theme.margin.default};
    font-family: ${(props) => props.theme.font.primaryFamily};
    font-size: ${(props) =>
      props.theme.font.text.normal.normal.mobile.fontSize};
    line-height: ${(props) =>
      props.theme.font.text.normal.normal.mobile.lineHeight};
    font-weight: ${() => weight};
    letter-spacing: ${(props) =>
      props.theme.font.text.normal.normal.mobile.letterSpacing};
    a {
      text-decoration: underline;
      &:link {
        color: ${(props) => props.theme.palette.default.greyMiddle};
      }
      &:active {
        color: ${(props) => props.theme.palette.default.greyMiddle};
      }
      &:visited {
        color: ${(props) => props.theme.palette.default.greyMiddle};
      }
    }
    b,
    strong {
      font-weight: ${() => bold};
    }
    @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
      font-size: ${(props) =>
        props.theme.font.text.normal.normal.desktop.fontSize};
      line-height: ${(props) =>
        props.theme.font.text.normal.normal.desktop.lineHeight};
      font-weight: ${() => weight};
      letter-spacing: ${(props) =>
        props.theme.font.text.normal.normal.desktop.letterSpacing};
    }
  `
}

export const TextMediumStyles = (props: TextProps) => {
  const { weight, bold } = props
  return css`
    color: ${(props) => props.theme.palette.default.greyMiddle};
    -webkit-font-smoothing: antialiased;
    word-wrap: break-word;
    margin-bottom: ${(props) => props.theme.margin.default};
    font-family: ${(props) => props.theme.font.primaryFamily};
    font-size: ${(props) =>
      props.theme.font.text.normal.medium.mobile.fontSize};
    line-height: ${(props) =>
      props.theme.font.text.normal.medium.mobile.lineHeight};
    font-weight: ${() => weight};
    letter-spacing: ${(props) =>
      props.theme.font.text.normal.medium.mobile.letterSpacing};
    a {
      text-decoration: underline;
      &:link {
        color: ${(props) => props.theme.palette.default.greyMiddle};
      }
      &:active {
        color: ${(props) => props.theme.palette.default.greyMiddle};
      }
      &:visited {
        color: ${(props) => props.theme.palette.default.greyMiddle};
      }
    }
    b,
    strong {
      font-weight: ${() => bold};
    }
    @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
      font-size: ${(props) =>
        props.theme.font.text.normal.medium.desktop.fontSize};
      line-height: ${(props) =>
        props.theme.font.text.normal.medium.desktop.lineHeight};
      font-weight: ${() => weight};
      letter-spacing: ${(props) =>
        props.theme.font.text.normal.medium.desktop.letterSpacing};
    }
  `
}

export const TextSmallStyles = (props: TextProps) => {
  const { weight, bold } = props
  return css`
    color: ${(props) => props.theme.palette.default.greyMiddle};
    -webkit-font-smoothing: antialiased;
    word-wrap: break-word;
    margin-bottom: ${(props) => props.theme.margin.default};
    font-family: ${(props) => props.theme.font.primaryFamily};
    font-size: ${(props) => props.theme.font.text.normal.small.mobile.fontSize};
    line-height: ${(props) =>
      props.theme.font.text.normal.small.mobile.lineHeight};
    font-weight: ${() => weight};
    letter-spacing: ${(props) =>
      props.theme.font.text.normal.small.mobile.letterSpacing};
    a {
      text-decoration: underline;
      &:link {
        color: ${(props) => props.theme.palette.default.greyMiddle};
      }
      &:active {
        color: ${(props) => props.theme.palette.default.greyMiddle};
      }
      &:visited {
        color: ${(props) => props.theme.palette.default.greyMiddle};
      }
    }
    b,
    strong {
      font-weight: ${() => bold};
    }
    @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
      font-size: ${(props) =>
        props.theme.font.text.normal.small.desktop.fontSize};
      line-height: ${(props) =>
        props.theme.font.text.normal.small.desktop.lineHeight};
      font-weight: ${() => weight};
      letter-spacing: ${(props) =>
        props.theme.font.text.normal.small.desktop.letterSpacing};
    }
  `
}

export const TextMiniStyles = (props: TextProps) => {
  const { weight, bold } = props
  return css`
    color: ${(props) => props.theme.palette.default.greyMiddle};
    -webkit-font-smoothing: antialiased;
    word-wrap: break-word;
    margin-bottom: ${(props) => props.theme.margin.default};
    font-family: ${(props) => props.theme.font.primaryFamily};
    font-size: ${(props) => props.theme.font.text.normal.mini.mobile.fontSize};
    line-height: ${(props) =>
      props.theme.font.text.normal.mini.mobile.lineHeight};
    font-weight: ${() => weight};
    letter-spacing: ${(props) =>
      props.theme.font.text.normal.mini.mobile.letterSpacing};
    a {
      text-decoration: underline;
      &:link {
        color: ${(props) => props.theme.palette.default.greyMiddle};
      }
      &:focus {
        color: ${(props) => props.theme.palette.default.greyMiddle};
      }
      &:active {
        color: ${(props) => props.theme.palette.default.greyMiddle};
      }
      &:visited {
        color: ${(props) => props.theme.palette.default.greyMiddle};
      }
    }
    b,
    strong {
      font-weight: ${() => bold};
    }
    @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
      font-size: ${(props) =>
        props.theme.font.text.normal.mini.desktop.fontSize};
      line-height: ${(props) =>
        props.theme.font.text.normal.mini.desktop.lineHeight};
      font-weight: ${() => weight};
      letter-spacing: ${(props) =>
        props.theme.font.text.normal.mini.desktop.letterSpacing};
    }
  `
}

export const getTextStyles = (props: TextProps) => {
  switch (props.size) {
    case FontSizeText.intro:
      return TextIntroStyles(props)
    case FontSizeText.normal:
      return TextNormalStyles(props)
    case FontSizeText.medium:
      return TextMediumStyles(props)
    case FontSizeText.small:
      return TextSmallStyles(props)
    case FontSizeText.mini:
      return TextMiniStyles(props)
    default:
      return TextNormalStyles(props)
  }
}

export const TextIntro = styled.p<TextProps>`
  ${(props) => getTextStyles(props)}
`
export const TextNormal = styled.p<TextProps>`
  ${(props) => getTextStyles(props)}
`
export const TextMedium = styled.p<TextProps>`
  ${(props) => getTextStyles(props)}
`
export const TextSmall = styled.p<TextProps>`
  ${(props) => getTextStyles(props)}
`
export const TextMini = styled.p<TextProps>`
  ${(props) => getTextStyles(props)}
`
