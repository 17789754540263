import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './IconMail.styles'

interface Props extends PropsWithClassName {}

const IconMail: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'icon-mail-root'}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6484 9.46484C19.8398 10.0977 18.8203 10.8711 15.2344 13.4727C14.5312 14 13.2305 15.1602 12 15.1602C10.7344 15.1602 9.46875 14 8.73047 13.4727C5.14453 10.8711 4.125 10.0977 3.31641 9.46484C3.17578 9.35938 3 9.46484 3 9.64062V16.8125C3 17.7617 3.73828 18.5 4.6875 18.5H19.3125C20.2266 18.5 21 17.7617 21 16.8125V9.64062C21 9.46484 20.7891 9.35938 20.6484 9.46484ZM12 14C12.8086 14.0352 13.9688 12.9805 14.5664 12.5586C19.2422 9.18359 19.5938 8.86719 20.6484 8.02344C20.8594 7.88281 21 7.63672 21 7.35547V6.6875C21 5.77344 20.2266 5 19.3125 5H4.6875C3.73828 5 3 5.77344 3 6.6875V7.35547C3 7.63672 3.10547 7.88281 3.31641 8.02344C4.37109 8.86719 4.72266 9.18359 9.39844 12.5586C9.99609 12.9805 11.1562 14.0352 12 14Z"
        fill="white"
      />
    </Root>
  )
}

export { IconMail }
