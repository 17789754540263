import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './LogoOpen.styles'

interface Props extends PropsWithClassName {
  color: 'dark' | 'light'
}

const LogoOpen: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { color } = props
  const colorLogo = color === 'dark' ? '#0b0b0b' : '#e9e9e9'
  return (
    <Root
      className={props.className}
      data-testid={'logo-open-root'}
      viewBox="0 0 122 11"
      width="122"
      height="11"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.06887 3.90282C7.06887 5.75753 5.62844 7.03007 3.60876 7.03007H1.96329V10.4355H0V0.791016H3.60876C5.62844 0.791016 7.06887 2.01719 7.06887 3.90282ZM1.96329 2.52724V5.30416H3.60876C4.52633 5.30416 5.06457 4.73228 5.06457 3.90282C5.06457 3.07335 4.52633 2.52724 3.60876 2.52724H1.96329Z"
        fill={colorLogo}
      />
      <path
        d="M15.3031 9.17859H11.3765L10.8383 10.4357H8.73145L13.022 0.61084H13.6781L17.9533 10.4357H15.8465L15.3082 9.17859H15.3031ZM13.3398 4.30997L12.0737 7.55572H14.6059L13.3398 4.30997Z"
        fill={colorLogo}
      />
      <path
        d="M27.343 10.4355L24.8568 7.03007H23.4164V10.4355H21.4531V0.791016H25.0619C27.0816 0.791016 28.522 2.01719 28.522 3.90281C28.522 5.14444 27.8659 6.12332 26.8355 6.63852L29.5523 10.4407H27.343V10.4355ZM23.4164 5.2887H25.0619C25.9795 5.2887 26.5177 4.73228 26.5177 3.89766C26.5177 3.06304 25.9795 2.52208 25.0619 2.52208H23.4164V5.28355V5.2887Z"
        fill={colorLogo}
      />
      <path
        d="M39.7923 2.5736H36.7116V10.4355H34.7483V2.5736H31.6777V0.791016H39.7923V2.5736Z"
        fill={colorLogo}
      />
      <path
        d="M58.4494 5.61315C58.4494 8.44674 56.153 10.6106 53.3285 10.6106C50.504 10.6106 48.2383 8.44674 48.2383 5.61315C48.2383 2.77955 50.5091 0.615723 53.3285 0.615723C56.1478 0.615723 58.4494 2.77955 58.4494 5.61315ZM50.2631 5.61315C50.2631 7.48332 51.5549 8.78677 53.3439 8.78677C55.1329 8.78677 56.4246 7.48332 56.4246 5.61315C56.4246 3.74298 55.1021 2.43953 53.3439 2.43953C51.5856 2.43953 50.2631 3.75328 50.2631 5.61315Z"
        fill={colorLogo}
      />
      <path
        d="M68.612 2.5736H64.265V4.98473H67.8277V6.76732H64.265V10.4355H62.3018V0.791016H68.612V2.5736Z"
        fill={colorLogo}
      />
      <path
        d="M87.7405 5.61315C87.7405 8.44674 85.444 10.6106 82.6195 10.6106C79.795 10.6106 77.5293 8.44674 77.5293 5.61315C77.5293 2.77955 79.8002 0.615723 82.6195 0.615723C85.4389 0.615723 87.7405 2.77955 87.7405 5.61315ZM79.5541 5.61315C79.5541 7.48332 80.8459 8.78677 82.6349 8.78677C84.4239 8.78677 85.7157 7.48332 85.7157 5.61315C85.7157 3.74298 84.3931 2.43953 82.6349 2.43953C80.8766 2.43953 79.5541 3.75328 79.5541 5.61315Z"
        fill={colorLogo}
      />
      <path
        d="M98.6441 3.90282C98.6441 5.75753 97.2036 7.03007 95.184 7.03007H93.5385V10.4355H91.5752V0.791016H95.184C97.2036 0.791016 98.6441 2.01719 98.6441 3.90282ZM93.5385 2.52724V5.30416H95.184C96.1015 5.30416 96.6398 4.73228 96.6398 3.90282C96.6398 3.07335 96.1015 2.52724 95.184 2.52724H93.5385Z"
        fill={colorLogo}
      />
      <path
        d="M108.849 2.5736H104.354V4.53136H107.655V6.21091H104.354V8.65295H109.024V10.4355H102.391V0.791016H108.849V2.5736Z"
        fill={colorLogo}
      />
      <path
        d="M121.087 0.791159V10.6108H120.431L114.874 4.64999V10.4357H112.911V0.61084H113.552L119.124 6.53047V0.786005H121.087V0.791159Z"
        fill={colorLogo}
      />
    </Root>
  )
}

export { LogoOpen }

LogoOpen.defaultProps = {
  color: 'light',
}
