import React from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './Col.styles'

interface Props extends PropsWithClassName {
  xs?: number
  xsOffset?: number
  sm?: number
  smOffset?: number
  md?: number
  mdOffset?: number
  lg?: number
  lgOffset?: number
  children?: React.ReactNode
}

const Col = (props: Props): React.ReactElement => {
  const { xs, xsOffset, sm, smOffset, md, mdOffset, lg, lgOffset, children } =
    props
  function renderClassNameString(): string {
    let classNameString = ''
    if (xs) {
      classNameString += ` col-xs-${xs}`
    }
    if (xsOffset) {
      classNameString += ` col-xs-offset-${xsOffset}`
    }
    if (sm) {
      classNameString += ` col-sm-${sm}`
    }
    if (smOffset) {
      classNameString += ` col-sm-offset-${smOffset}`
    }
    if (md) {
      classNameString += ` col-md-${md}`
    }
    if (mdOffset) {
      classNameString += ` col-md-offset-${mdOffset}`
    }
    if (lg) {
      classNameString += ` col-lg-${lg}`
    }
    if (lgOffset) {
      classNameString += ` col-lg-offset-${lgOffset}`
    }
    return classNameString
  }
  return (
    <Root
      className={(props.className || '') + renderClassNameString()}
      data-testid={'Col-root'}
    >
      {children}
    </Root>
  )
}

export { Col }
