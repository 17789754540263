import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './IconNew.styles'

interface Props extends PropsWithClassName {}

const IconNew: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'icon-new-root'}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Plus icon</title>
      <desc>Plus icon, um interaktives Element zu öffnen</desc>
      <path
        d="M13.472 10.08H19.52V13.504H13.472V19.52H10.048V13.504H4V10.08H10.048V4H13.472V10.08Z"
        fill="white"
      />
    </Root>
  )
}

export { IconNew }
