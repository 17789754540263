import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './IconLinkInternal.styles'

interface Props extends PropsWithClassName {}

const IconLinkInternal: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'icon-link-internal-root'}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6797 5.01074C10.3281 5.3623 10.3633 5.88965 10.6797 6.24121L14.9336 10.249H4.84375C4.35156 10.249 4 10.6357 4 11.0928V12.2178C4 12.71 4.35156 13.0615 4.84375 13.0615H14.9336L10.6797 17.1045C10.3633 17.4561 10.3633 17.9834 10.6797 18.335L11.4531 19.1084C11.8047 19.4248 12.332 19.4248 12.6484 19.1084L19.5039 12.2529C19.8203 11.9365 19.8203 11.4092 19.5039 11.0576L12.6484 4.2373C12.332 3.9209 11.8047 3.9209 11.4531 4.2373L10.6797 5.01074Z"
        fill="white"
      />
    </Root>
  )
}

export { IconLinkInternal }
