import { NextParsedUrlQuery } from 'next/dist/server/request-meta'

export const getOrigin = (): string => {
  const hostname = process.env.NEXT_PUBLIC_BASE_URL
  return (hostname?.startsWith('http') ? '' : 'https://') + hostname
}

export const hasUrlInSlug = (
  url: string,
  query: NextParsedUrlQuery
): boolean => {
  if (Array.isArray(query.slug)) {
    const match = query.slug.find((slug: string): boolean => {
      return url.endsWith(slug)
    })

    return !!match
  }
  return url.endsWith(query.slug as string)
}
