import React from 'react'

import { PropsWithClassName } from '@Components/helper'

import { H1, H2, H3, H4, H5 } from './Headline.styles'

export enum HeadlineImportances {
  h1 = 1,
  h2 = 2,
  h3 = 3,
  h4 = 4,
  h5 = 5,
}

export interface HeadlineProps extends PropsWithClassName {
  children: string | React.ReactNode
  importance: HeadlineImportances
  styleAs: HeadlineImportances
}

const Headline = (props: HeadlineProps): React.ReactElement => {
  const { className, children, importance, styleAs } = props

  function renderHeadline(importance: number): JSX.Element {
    switch (importance) {
      case HeadlineImportances.h1:
        return (
          <H1
            styleAs={styleAs}
            importance={importance}
            data-testid={'Headline-root'}
            className={className}
          >
            {children}
          </H1>
        )
      case HeadlineImportances.h2:
        return (
          <H2
            styleAs={styleAs}
            importance={importance}
            data-testid={'Headline-root'}
            className={className}
          >
            {children}
          </H2>
        )
      case HeadlineImportances.h3:
        return (
          <H3
            styleAs={styleAs}
            importance={importance}
            data-testid={'Headline-root'}
            className={className}
          >
            {children}
          </H3>
        )
      case HeadlineImportances.h4:
        return (
          <H4
            styleAs={styleAs}
            importance={importance}
            data-testid={'Headline-root'}
            className={className}
          >
            {children}
          </H4>
        )
      case HeadlineImportances.h5:
        return (
          <H5
            styleAs={styleAs}
            importance={importance}
            data-testid={'Headline-root'}
            className={className}
          >
            {children}
          </H5>
        )
      default:
        return (
          <H1
            styleAs={styleAs}
            importance={importance}
            data-testid={'Headline-root'}
            className={className}
          >
            {children}
          </H1>
        )
    }
  }

  return renderHeadline(importance)
}

export { Headline }
