import React from 'react'

import { PropsWithClassName } from '@Components/helper'
import { Routes } from '@Components/Routes'

import {
  ButtonIcon,
  RealButton,
  Root,
  StyledButtonLink,
  StyledLoadingSpinner,
} from './Button.styles'

export enum ButtonModifier {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
}

export enum ButtonSize {
  small = 'small',
  normal = 'normal',
}

export enum ButtonType {
  submit = 'submit',
  button = 'button',
  reset = 'reset',
}

interface Props extends PropsWithClassName {
  modifier: ButtonModifier
  children?: string | JSX.Element
  type: ButtonType
  disabled?: boolean
  size?: ButtonSize
  loadingIndicator?: boolean
  onClick?: () => void
  icon?: React.ReactNode
  dataTestId?: string
  href?: string | Routes
  external?: boolean
}

const Button = (props: Props): React.ReactElement => {
  const {
    icon,
    modifier,
    children,
    type,
    disabled,
    onClick,
    loadingIndicator,
    dataTestId,
    className,
    href,
    external,
  } = props
  const handleClick = (): void => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <Root
      className={className}
      data-testid={dataTestId ? dataTestId : 'Button-root'}
    >
      {!href && (
        <RealButton
          $loading={loadingIndicator ? loadingIndicator : false}
          icon={icon !== undefined}
          noText={!!children}
          modifier={modifier}
          disabled={disabled}
          type={type}
          onClick={() => handleClick()}
        >
          {!loadingIndicator && icon}
          {loadingIndicator && (
            <ButtonIcon>
              <StyledLoadingSpinner
                invertColors={modifier === ButtonModifier.primary}
                isPositionAbsolute
              />
            </ButtonIcon>
          )}
          {children}
        </RealButton>
      )}
      {href && (
        <StyledButtonLink
          passHref
          href={href}
          $loading={loadingIndicator ? loadingIndicator : false}
          icon={icon !== undefined}
          noText={!!children}
          modifier={modifier}
          type={type}
          target={external ? '_blank' : '_self'}
          rel={external ? 'noreferrer' : ''}
        >
          {!loadingIndicator && icon}
          {loadingIndicator && (
            <ButtonIcon>
              <StyledLoadingSpinner
                invertColors={modifier === ButtonModifier.primary}
                isPositionAbsolute
              />
            </ButtonIcon>
          )}
          {children}
        </StyledButtonLink>
      )}
    </Root>
  )
}

export { Button }
