import styled from 'styled-components'

import Button from '@Components/atoms/Button'

export const Root = styled(Button)`
  position: fixed;
  left: 14px;
  bottom: 70px;
  z-index: 50;
  margin-bottom: 0;
  transition: all 400ms ease-in-out;
  button {
    margin-bottom: 0;
  }
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    left: 50px;
  }
`
