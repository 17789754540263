import React from 'react'

import { PropsWithClassName } from '@Components/helper'

import { LinePink, LineWhite, Root } from './BackgroundMask.styles'

interface Props extends PropsWithClassName {
  top: number
  left: number
}

const BackgroundMask = (props: Props): React.ReactElement => {
  const { top, left } = props
  return (
    <Root
      className={props.className}
      data-testid={'BackgroundMask-root'}
      width="593"
      height="741"
      viewBox="0 0 593 741"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      style={{
        top: top,
        left: left,
      }}
    >
      <LinePink d="M177.62,251.04c14.17-.3,25.41-12.03,25.11-26.2-.29-13.55-11.07-24.54-24.61-25.09-13.86-.29-25.33,10.71-25.62,24.56,0,.18,0,.36,0,.54-.37,14.09,10.75,25.81,24.84,26.18,.1,0,.2,0,.29,0Z" />
      <LinePink d="M115.6,150.61c-6.7-.39-12.46,4.73-12.84,11.43-.39,6.7,4.73,12.46,11.43,12.84,6.7,.39,12.46-4.73,12.84-11.43,.01-.19,.02-.38,.02-.57,.07-6.5-4.97-11.9-11.45-12.28Z" />
      <LinePink d="M160.56,185.86c-1.08-6.07,2.97-11.86,9.03-12.94,.64-.11,1.28-.17,1.93-.17,7.78-.42,12.22-4.57,12.69-12.47,.92-14.53,4.66-80.88,5.94-99.43,.45-6.5-3.01-14.31-9.18-16.9-4.13-1.59-8.54-2.35-12.97-2.23-6.55,0-11.96-3.51-12.63-9.45-1.26-10.74-5.35-14.14-16.06-14.53-11.63-.39-65.48-4.41-81.52-5.58-10.29,.19-18.47,8.68-18.28,18.97,.01,.7,.06,1.39,.16,2.09,1.28,8.7-2.2,13.22-10.88,13.94-7.16,.26-12.75,6.27-12.49,13.43,0,.17,.02,.34,.03,.52,0,1.45-4.96,116.55-5.58,125.5-.81,10.65,6.36,20.81,17.18,21.22,7.47,.34,11.16,3.9,11.6,11.41,.64,10.42,9.61,18.36,20.03,17.72,.26-.02,.52-.04,.78-.06,16.2-2.18,32.46-4.18,48.78-5.58,9.85-.81,16.4-4.1,16.73-14.53,.4-5.93,5.53-10.42,11.46-10.02,.4,.03,.8,.08,1.2,.15,14.42,2.04,23.82-6.97,22.06-21.06Zm-14.92-14.25c-9.7-1.77-18.99,4.65-20.77,14.35-.35,1.93-.38,3.9-.1,5.84,1.23,8.81-3.63,14.73-12.61,14.2-12.47-.78-48.86-4.68-54.66-5.44-6.72-.84-9.96-4.43-10.29-11.16-1.92-37.1-6.02-124.59-5.86-131.22,.14-5.58,5.83-9.85,11.52-10.1,12.75-.56,73.77-3.93,90.36-4.91,5.81-.35,10.81,4.08,11.16,9.89,.03,.42,.03,.84,0,1.27,0,6.5,3.46,75.86,5.1,104.64,.56,8.79-5.16,13.97-13.86,12.63h0Z" />
      <LineWhite d="M167.07,239c14.17-.3,25.41-12.03,25.11-26.2-.29-13.55-11.07-24.54-24.61-25.09-13.86-.29-25.33,10.71-25.62,24.56,0,.18,0,.36,0,.54-.37,14.09,10.75,25.81,24.84,26.18,.1,0,.2,0,.29,0Z" />
      <LineWhite d="M105.05,138.57c-6.7-.39-12.46,4.73-12.84,11.43-.39,6.7,4.73,12.46,11.43,12.84,6.7,.39,12.46-4.73,12.84-11.43,.01-.19,.02-.38,.02-.57,.07-6.5-4.97-11.9-11.45-12.28Z" />
      <LineWhite d="M150.01,173.82c-1.08-6.07,2.97-11.86,9.03-12.94,.64-.11,1.28-.17,1.93-.17,7.78-.42,12.22-4.57,12.69-12.47,.92-14.53,4.66-80.88,5.94-99.43,.45-6.5-3.01-14.31-9.18-16.9-4.13-1.59-8.54-2.35-12.97-2.23-6.55,0-11.96-3.51-12.63-9.45-1.26-10.74-5.35-14.14-16.06-14.53C117.13,5.31,63.27,1.3,47.23,.13c-10.29,.19-18.47,8.68-18.28,18.97,.01,.7,.06,1.39,.16,2.09,1.28,8.7-2.2,13.22-10.88,13.94-7.16,.26-12.75,6.27-12.49,13.43,0,.17,.02,.34,.03,.52,0,1.45-4.96,116.55-5.58,125.5-.81,10.65,6.36,20.81,17.18,21.22,7.47,.34,11.16,3.9,11.6,11.41,.64,10.42,9.61,18.36,20.03,17.72,.26-.02,.52-.04,.78-.06,16.2-2.18,32.46-4.18,48.78-5.58,9.85-.81,16.4-4.1,16.73-14.53,.4-5.93,5.53-10.42,11.46-10.02,.4,.03,.8,.08,1.2,.15,14.42,2.04,23.82-6.97,22.06-21.06Zm-14.92-14.25c-9.7-1.77-18.99,4.65-20.77,14.35-.35,1.93-.38,3.9-.1,5.84,1.23,8.81-3.63,14.73-12.61,14.2-12.47-.78-48.86-4.68-54.66-5.44-6.72-.84-9.96-4.43-10.29-11.16-1.92-37.1-6.02-124.59-5.86-131.22,.14-5.58,5.83-9.85,11.52-10.1,12.75-.56,73.77-3.93,90.36-4.91,5.81-.35,10.81,4.08,11.16,9.89,.03,.42,.03,.84,0,1.27,0,6.5,3.46,75.86,5.1,104.64,.56,8.79-5.16,13.97-13.86,12.63h0Z" />
    </Root>
  )
}

export { BackgroundMask }
