import styled from 'styled-components'

export const Root = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 300%;
  height: auto;
  z-index: 1;
  transform-origin: 0% 0%;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  transform: rotate(25deg);
  overflow: hidden;
  &.services-mask {
    z-index: -1;
  }
`

export const LinePink = styled.path`
  stroke: ${(props) => props.theme.palette.default.primary};
  stroke-miterlimit: 10;
  stroke-width: 0.1px;
  opacity: 0.8;
  fill: none;
`

export const LineWhite = styled.path`
  stroke: ${(props) => props.theme.palette.default.white};
  opacity: 0.3;
  stroke-miterlimit: 10;
  stroke-width: 0.1px;
  fill: none;
`
