import styled from 'styled-components'

import BackgroundMask from '@Components/atoms/BackgroundMask'
import Button from '@Components/atoms/Button'
import Col from '@Components/atoms/Col'
import Eyebrow from '@Components/atoms/Eyebrow'

export const Root = styled.div`
  background: ${(props) => props.theme.palette.default.blueDeep};
  position: relative;
  padding-top: 11rem;
  max-height: 15.625rem;
  overflow: hidden;
  margin-bottom: ${(props) => props.theme.margin.default};
  &.job-fallback {
    margin-bottom: 0 !important;
  }
`

export const StyledCol = styled(Col)`
  display: flex;
  position: relative;
  flex-direction: row;
  align-content: center;
  z-index: 10;
  margin-bottom: ${(props) => props.theme.margin.regular};
`

export const StyledButton = styled(Button)`
  text-align: center;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => props.theme.margin.regular};
  transition: all 200ms;
  cursor: pointer;
  z-index: 50;
  svg {
    position: relative;
    margin-left: 0.25rem;
    path {
      stroke: ${(props) => props.theme.palette.default.white};
      stroke-width: 3px;
      fill: none;
    }
  }
  &:hover {
    svg {
      path {
        fill: none;
      }
    }
  }
`
export const StyledEyebrow = styled(Eyebrow)`
  color: ${(props) => props.theme.palette.default.white};
  margin: 0.5rem 0 0 0;
`

export const StyledBackgroundMask = styled(BackgroundMask)`
  &.content-page-head {
    position: absolute;
    top: 0;
    width: 750%;

    @media (min-width: ${(props) => props.theme.device.desktop}) {
      width: 300% !important;
    }

    @media (min-width: ${(props) => props.theme.device.tablet}) {
      width: 350%;
    }

    @media (min-width: ${(props) => props.theme.device.mobile}) {
      width: 500%;
      left: -20%;
    }

    @media (min-width: ${(props) => props.theme.device.smartphone}) {
      width: 600%;
      left: -50%;
    }
  }
`
