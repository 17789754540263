import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './IconError.styles'

interface Props extends PropsWithClassName {}

const IconError: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'icon-error-root'}
      width="24"
      height="24"
      viewBox="0 0 100.5 102.78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <svg
        width="100"
        height="100"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30 15C30 6.77419 23.2258 0 15 0C6.71371 0 0 6.77419 0 15C0 23.2863 6.71371 30 15 30C23.2258 30 30 23.2863 30 15ZM15 18.0242C16.5121 18.0242 17.7823 19.2944 17.7823 20.8065C17.7823 22.379 16.5121 23.5887 15 23.5887C13.4274 23.5887 12.2177 22.379 12.2177 20.8065C12.2177 19.2944 13.4274 18.0242 15 18.0242ZM12.3387 8.04436C12.2782 7.62097 12.6411 7.25806 13.0645 7.25806H16.875C17.2984 7.25806 17.6613 7.62097 17.6008 8.04436L17.1774 16.2702C17.1169 16.6935 16.8145 16.9355 16.4516 16.9355H13.4879C13.125 16.9355 12.8226 16.6935 12.7621 16.2702L12.3387 8.04436Z"
          fill="#C2067B"
        />
      </svg>
    </Root>
  )
}

export { IconError }
