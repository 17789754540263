import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './IconXing.styles'

interface Props extends PropsWithClassName {}

const IconXing: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'icon-xing-root'}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9455 10.6328C10.8752 10.7734 10.0666 12.2148 8.48457 14.9922C8.30879 15.2734 8.09785 15.4141 7.85176 15.4141H5.5666C5.32051 15.4141 5.14473 15.168 5.28535 14.9219L7.71113 10.668C7.71113 10.668 7.71113 10.668 7.71113 10.6328L6.16426 7.99609C6.02363 7.71484 6.16426 7.50391 6.44551 7.50391H8.76582C9.01192 7.50391 9.22285 7.64453 9.39863 7.92578L10.9455 10.6328ZM18.6799 4.90234L13.6174 13.7969L16.8518 19.6328C16.9924 19.9141 16.8518 20.1602 16.5354 20.1602H14.2502C13.9689 20.1602 13.758 20.0195 13.6174 19.7031L10.383 13.7969C10.4885 13.5859 12.176 10.5977 15.4455 4.83203C15.6213 4.55078 15.8322 4.375 16.0783 4.375H18.3986C18.6799 4.375 18.8205 4.62109 18.6799 4.90234Z"
        fill="white"
      />
    </Root>
  )
}

export { IconXing }
