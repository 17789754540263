import React from 'react'

import { Root } from './Container.styles'
import { BackgroundColors } from './Container.styles'

interface Props {
  children: React.ReactNode
  fluid?: boolean
  background?: BackgroundColors
}

const Container = (props: Props): React.ReactElement => {
  const { children, fluid, background } = props
  return (
    <Root
      className={`container${fluid ? '-fluid' : ''}`}
      data-testid={'Container-root'}
      background={background}
    >
      {children}
    </Root>
  )
}

export { Container }
