import Link from 'next/link'
import styled from 'styled-components'

import LogoOpen from '@Components/atoms/Logos/LogoOpen'
import LogoText from '@Components/atoms/Logos/LogoText'
import NavigationMain from '@Components/molecules/NavigationMain'

import { defaultTheme } from '@Assets/styles/theme/theme'

import { PageBackground } from './DefaultTemplate'

interface RootProps {
  background: PageBackground | undefined
}

export const getBackgroundColor = (props: PageBackground | undefined) => {
  switch (props) {
    case PageBackground.white:
      return defaultTheme.palette.default.white
    case PageBackground.bluedeep:
      return defaultTheme.palette.default.blueDeep
    default:
      return 'transparent'
  }
}

export const Root = styled.div<RootProps>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  background: ${(props) => getBackgroundColor(props.background)};
`

// excludedProps is used to exclude props from being passed to the DOM (to avoid
// React warnings)
const excludedProps = ['sticky', 'visible']

interface TemplateProps {
  sticky: boolean
  visible: boolean
}

export const StyledLogo = styled(LogoText)`
  transition: all 400ms ease-in-out;
  @media screen and (max-width: ${(props) => props.theme.device.tablet}) {
    height: 1.75rem;
    margin-left: 0.875rem;
  }
`
export const StyledLogoOpen = styled(LogoOpen)`
  height: 0.631rem;
  width: 7.638rem;
  transition: all 400ms ease-in-out;
  margin-left: 1.263rem;
  margin-top: 0.75rem;
  @media screen and (max-width: ${(props) => props.theme.device.tablet}) {
    height: 0.543rem;
    margin-left: -1rem;
    margin-top: 0.6rem;
  }
  @media screen and (max-width: 340px) {
    display: none;
  }
`

export const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !excludedProps.includes(prop),
})<TemplateProps>`
  display: flex;
  align-items: center;
  width: 25.5rem;
  height: 2.375rem;
  position: fixed;
  top: 1.313rem;
  left: 3.125rem;
  z-index: 99;
  transform: ${(props) =>
    props.visible ? 'translateY(0px)' : 'translateY(-74px)'};
  opacity: ${(props) => (props.visible ? '1' : '0')};
  transition: all 400ms ease-in-out;
  @media screen and (max-width: ${(props) => props.theme.device.tablet}) {
    transform: translateX(-4rem);
  }
`

export const StyledNavigationMain = styled(NavigationMain).withConfig({
  shouldForwardProp: (prop) => !excludedProps.includes(prop),
})<TemplateProps>`
  transform: ${(props) =>
    props.visible ? 'translateY(0px)' : 'translateY(-74px)'};
  opacity: ${(props) => (props.visible ? '1' : '0')};
`

interface BarProps {
  visible: boolean
}

export const Bar = styled.div.withConfig({
  shouldForwardProp: (prop) => !excludedProps.includes(prop),
})<BarProps>`
  width: 100%;
  height: ${(props) => (props.visible ? '4.625rem' : '0')};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  transition: all 400ms ease-in-out;
  background: rgba(0, 0, 0, ${(props) => (props.visible ? '1' : '0')});
`

export const Main = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  z-index: 50;
  overflow: hidden;
  @media screen and (max-width: ${(props) => props.theme.device.tablet}) {
    margin-top: 4.625rem;
  }
`
export const Content = styled.div`
  position: relative;
  z-index: 20;
`
