import React from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './BackgroundSectionTeaser.styles'

interface Props extends PropsWithClassName {}

const BackgroundSectionTeaser = (props: Props): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'BackgroundSectionTeaser-root'}
      width="1440"
      height="1259"
      viewBox="0 0 1440 1259"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4">
        <path
          d="M1001.31 2006.14C1010.84 1977.43 1033.17 1954.89 1062.31 1944.56C1091.44 1934.23 1124.4 1937.16 1152.35 1952.58C1220.09 1985.76 1276.1 1973.28 1315.18 1912.28C1387.51 1800.18 1713.88 1286.15 1807.13 1143.58C1839.77 1093.61 1845.18 1014.81 1804.51 965.091C1776.45 932.797 1742.55 906.078 1704.6 886.332C1649.1 855.562 1618.9 802.007 1639.62 751.27C1676.69 659.348 1657.09 612.841 1568.13 559.433C1471.39 501.704 1033.2 216.693 902.606 132.021C857.26 107.902 804.373 103.239 757.205 119.198C710.038 135.156 673.07 170.228 655.564 215.614C627.776 291.353 578.187 311.185 501.519 276.273C471.087 260.725 436.019 257.612 404.409 267.65C372.799 277.689 347.378 300.012 334.014 329.469C327.572 341.089 -225.737 1239.95 -270.7 1308.79C-324.873 1390.35 -309.273 1505.34 -219.5 1559.49C-157.695 1597.26 -142.379 1643.15 -171.925 1705.35C-192.092 1748.01 -193.063 1797.64 -174.612 1842.78C-156.161 1887.91 -119.883 1924.63 -74.1668 1944.47C72.7054 2003.12 219.309 2063.36 363.658 2128.79C450.616 2168.53 520.732 2172.93 569.899 2090.93C585.221 2067.39 610.47 2051.78 639.314 2048.03C668.158 2044.28 697.859 2052.73 720.972 2071.28C834.025 2155.28 953.627 2127.23 1001.31 2006.14ZM938.268 1821.91C894.889 1787.02 837.802 1773.83 784.965 1786.5C732.127 1799.16 690.035 1836.12 671.926 1885.75C643.17 1962.12 575.814 1986.7 502.124 1940.3C400.028 1875.52 109.18 1673.36 63.4024 1640.09C10.2036 1601.84 -1.21241 1557.82 25.8122 1502.4C174.284 1196.18 528.194 475.982 559.096 423.588C585.054 379.552 652.183 372.074 701.476 396.775C811.88 452.144 1343.59 711.591 1488.45 781.678C1514.74 794.361 1534.71 817.133 1543.29 844.231C1551.88 871.329 1548.26 900.16 1533.36 923.431C1504.49 975.493 1225.67 1547.44 1111.76 1785.76C1077.46 1858.77 1006 1873.49 938.269 1821.91L938.268 1821.91Z"
          fill="white"
          stroke="#BFBFBF"
        />
        <path
          d="M1070.11 1921.7C1077.1 1892.27 1097.38 1867.86 1125.5 1855.03C1153.63 1842.2 1186.72 1842.26 1215.9 1855.18C1286.28 1882.33 1340.99 1865.02 1374.6 1800.84C1436.89 1682.86 1717.22 1142.34 1797.68 992.187C1825.85 939.565 1824.36 860.592 1779.51 814.609C1748.75 784.883 1712.65 761.219 1673.12 744.856C1615.15 719.041 1580.4 668.321 1596.61 615.972C1625.53 521.169 1601.95 476.547 1508.68 431.095C1407.27 382.018 945.911 136.282 808.438 63.3138C761.162 43.2394 708.071 43.2031 662.473 63.2121C616.876 83.2211 583.105 121.381 569.622 168.121C548.541 245.993 500.868 270.071 421.45 241.975C389.778 229.138 354.572 229.093 323.958 241.848C293.343 254.603 269.964 279.057 259.219 309.567C253.814 321.704 -219.049 1265.37 -257.84 1337.87C-304.699 1423.84 -279.137 1537.03 -184.985 1583.15C-120.124 1615.39 -100.866 1659.77 -124.879 1724.31C-141.251 1768.57 -137.892 1818.09 -115.578 1861.44C-93.2644 1904.8 -53.9239 1938.22 -6.65224 1954C144.772 1999.62 296.069 2046.86 445.571 2099.45C535.661 2131.46 605.895 2129.74 647.728 2043.77C660.939 2018.98 684.732 2001.23 713.139 1994.98C741.546 1988.73 771.872 1994.56 796.513 2011.02C916.457 2084.85 1033.16 2046.49 1070.11 1921.7ZM991.249 1743.66C944.993 1712.69 886.974 1704.52 835.442 1721.75C783.909 1738.97 745.198 1779.45 731.483 1830.47C709.493 1909.06 644.536 1939.42 567.082 1899.62C459.728 1843.98 152.368 1667.94 103.865 1638.79C47.5345 1605.31 32.3259 1562.46 54.4174 1504.9C175.635 1186.9 465.43 438.599 491.647 383.71C513.669 337.58 579.89 324.28 631.149 344.591C745.958 390.126 1298.26 602.245 1448.67 659.44C1475.97 669.783 1497.85 690.728 1508.76 716.974C1519.68 743.221 1518.59 772.257 1505.77 796.739C1481.55 851.119 1253.64 1445.19 1160.93 1692.54C1133.12 1768.25 1063.22 1789.14 991.25 1743.67L991.249 1743.66Z"
          fill="white"
          stroke="#BFBFBF"
        />
      </g>
    </Root>
  )
}

export { BackgroundSectionTeaser }
