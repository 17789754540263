import Link from 'next/link'
import styled from 'styled-components'

import BackgroundSectionTeaser from '@Components/atoms/BackgroundSectionTeaser'
import Col from '@Components/atoms/Col'
import {
  IconFacebook,
  IconInstagram,
  IconLinkedin,
  IconTwitter,
} from '@Components/atoms/Icons'
import IconXing from '@Components/atoms/Icons/IconXing'
import IconYoutube from '@Components/atoms/Icons/IconYoutube'
import Logo from '@Components/atoms/Logos/Logo'
import LogoText from '@Components/atoms/Logos/LogoText'
import Row from '@Components/atoms/Row'
import { FontSizeText, FontWeightText, Text } from '@Components/atoms/Text/Text'
import { getTextStyles } from '@Components/atoms/Text/Text.styles'

export const Root = styled.footer`
  font-family: ${(props) => props.theme.font.primaryFamily};
  background: ${(props) => props.theme.palette.default.white};
  position: relative;
  overflow: hidden;
`

export const Nav = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    align-items: flex-start;
    justify-content: center;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
`
export const StyledRow = styled(Row)`
  padding: ${(props) => props.theme.padding.default} 0;
  position: relative;
  z-index: 10;
`
export const FooterNavigation = styled.nav`
  display: flex;
  margin-bottom: ${(props) => props.theme.margin.default};
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    margin-bottom: 0;
  }
`

export const List = styled.ul`
  list-style-type: none;
  margin-right: 2rem;
  &:last-of-type {
    margin-right: 0;
  }
`

export const ListItem = styled.li`
  margin-bottom: ${(props) => props.theme.margin.half};
  &:last-of-type {
    margin-bottom: 0;
  }
`

export const StyledLink = styled(Link)`
  ${getTextStyles({
    size: FontSizeText.medium,
    weight: FontWeightText.normal,
    bold: FontWeightText.bold,
  })}
  color: ${(props) => props.theme.palette.default.black};
  font-feature-settings: 'salt' !important;
  &:hover {
    text-decoration: underline;
  }
`
export const StyledLinkLogo = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.palette.default.black};
  &:hover {
    text-decoration: underline;
  }
`

export const StyledLogo = styled(Logo)`
  margin-bottom: ${(props) => props.theme.margin.half};
`
export const StyledLogoText = styled(LogoText)`
  margin-bottom: 0.938rem;
`

export const CenteredCol = styled(Col)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${(props) => props.theme.margin.default};
  ${StyledLogo} {
    path {
      stroke: black;
      fill: black;
    }
  }
  ${StyledLogoText} {
    path {
      fill: black;
    }
  }
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    margin-bottom: 0;
  }
`

export const RightCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Icon = styled.img`
  margin-right: 1rem;
`

export const FooterBar = styled.div`
  width: 100%;
  padding: ${(props) => props.theme.padding.half} 0;
  color: ${(props) => props.theme.palette.default.white};
  text-align: center;
  background: ${(props) => props.theme.palette.default.blueDeep};
  position: relative;
  z-index: 10;
`

export const BottomLink = styled(Link)`
  ${getTextStyles({
    size: FontSizeText.medium,
    weight: FontWeightText.normal,
    bold: FontWeightText.bold,
  })}
  color: ${(props) => props.theme.palette.default.white};
  &:hover {
    text-decoration: underline;
  }
  margin-right: ${(props) => props.theme.margin.mini};
  white-space: nowrap;
  &:last-of-type {
    margin: 0;
  }

  &.bottom {
    display: block;
    margin-bottom: ${(props) => props.theme.margin.small};
    &:last-of-type {
      margin-right: 0;
    }
    @media screen and (min-width: ${(props) => props.theme.device.smartphone}) {
      display: inline-block;
      margin-right: ${(props) => props.theme.margin.default};
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    margin-right: ${(props) => props.theme.margin.small};
  }
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    margin-right: ${(props) => props.theme.margin.half};
  }
`

export const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    justify-content: flex-start;
  }
`

export const StyledSocialLink = styled(Link)`
  color: ${(props) => props.theme.palette.default.black};
  margin-right: ${(props) => props.theme.margin.small};
  &:last-of-type {
    margin-right: 0;
  }
`
export const StyledIconBrandFacebook = styled(IconFacebook)`
  transition: 100ms ease-out;
  transform: scale(1.1);
  path {
    fill: ${(props) => props.theme.palette.default.black};
  }
  &:hover {
    transform: scale(1.3);
  }
`
export const StyledIconBrandInstagram = styled(IconInstagram)`
  transition: 100ms ease-out;
  transform: scale(1.1);
  path {
    fill: ${(props) => props.theme.palette.default.black};
  }
  &:hover {
    transform: scale(1.3);
  }
`
export const StyledIconBrandLinkedin = styled(IconLinkedin)`
  transition: 100ms ease-out;
  transform: scale(1.1);
  path {
    fill: ${(props) => props.theme.palette.default.black};
  }
  &:hover {
    transform: scale(1.3);
  }
`
export const StyledIconBrandTwitter = styled(IconTwitter)`
  transition: 100ms ease-out;
  transform: scale(1.1);
  path {
    fill: ${(props) => props.theme.palette.default.black};
  }
  &:hover {
    transform: scale(1.3);
  }
`

export const StyledIconBrandYoutube = styled(IconYoutube)`
  transition: 100ms ease-out;
  transform: scale(1.1);
  path {
    fill: ${(props) => props.theme.palette.default.black};
  }
  &:hover {
    transform: scale(1.3);
  }
`

export const StyledIconBrandXing = styled(IconXing)`
  transition: 100ms ease-out;
  transform: scale(1.1);
  path {
    fill: ${(props) => props.theme.palette.default.black};
  }
  &:hover {
    transform: scale(1.3);
  }
`

export const StyledBackgroundSectionTeaser = styled(BackgroundSectionTeaser)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1;
`

export const StyledText = styled(Text)`
  color: ${(props) => props.theme.palette.default.black};
  text-align: center;
  margin-bottom: ${(props) => props.theme.margin.half};
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    text-align: left;
  }
`
