import styled, { css } from 'styled-components'

import { typography } from '@Components/helper/typography'

import { FontSizeEyebrow } from './Eyebrow'

const normal = css`
  max-width: ${() => typography.maxWidth};
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
  margin-bottom: ${(props) => props.theme.margin.half};
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text.eyebrow.normal.mobile.fontSize};
  font-weight: ${(props) =>
    props.theme.font.text.eyebrow.normal.mobile.fontWeight};
  line-height: ${(props) =>
    props.theme.font.text.eyebrow.normal.mobile.lineHeight};
  letter-spacing: ${(props) =>
    props.theme.font.text.eyebrow.normal.mobile.letterSpacing};
  text-transform: uppercase;
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    font-family: ${(props) => props.theme.font.text.primaryFamily};
    font-size: ${(props) =>
      props.theme.font.text.eyebrow.normal.desktop.fontSize};
    font-weight: ${(props) =>
      props.theme.font.text.eyebrow.normal.desktop.fontWeight};
    line-height: ${(props) =>
      props.theme.font.text.eyebrow.normal.desktop.lineHeight};
    letter-spacing: ${(props) =>
      props.theme.font.text.eyebrow.normal.desktop.letterSpacing};
  }
`
const small = css`
  max-width: ${() => typography.maxWidth};
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
  margin-bottom: ${(props) => props.theme.margin.half};
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text.eyebrow.small.mobile.fontSize};
  font-weight: ${(props) =>
    props.theme.font.text.eyebrow.small.mobile.fontWeight};
  line-height: ${(props) =>
    props.theme.font.text.eyebrow.small.mobile.lineHeight};
  letter-spacing: ${(props) =>
    props.theme.font.text.eyebrow.small.mobile.letterSpacing};
  text-transform: uppercase;
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    font-family: ${(props) => props.theme.font.text.primaryFamily};
    font-size: ${(props) =>
      props.theme.font.text.eyebrow.small.desktop.fontSize};
    font-weight: ${(props) =>
      props.theme.font.text.eyebrow.small.desktop.fontWeight};
    line-height: ${(props) =>
      props.theme.font.text.eyebrow.small.desktop.lineHeight};
    letter-spacing: ${(props) =>
      props.theme.font.text.eyebrow.small.desktop.letterSpacing};
  }
`

const getEyebrowStyles = (size: FontSizeEyebrow) => {
  switch (size) {
    case FontSizeEyebrow.normal:
      return normal
    case FontSizeEyebrow.small:
      return small
    default:
      return normal
  }
}

interface EyebrowProps {
  size: FontSizeEyebrow
}

export const EyebrowH1 = styled.h1<EyebrowProps>`
  ${(props) => getEyebrowStyles(props.size)}
`
export const EyebrowH2 = styled.h2<EyebrowProps>`
  ${(props) => getEyebrowStyles(props.size)}
`
export const EyebrowH3 = styled.h3<EyebrowProps>`
  ${(props) => getEyebrowStyles(props.size)}
`
export const EyebrowH4 = styled.h4<EyebrowProps>`
  ${(props) => getEyebrowStyles(props.size)}
`
export const EyebrowH5 = styled.h5<EyebrowProps>`
  ${(props) => getEyebrowStyles(props.size)}
`
