import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './IconFacebook.styles'

interface Props extends PropsWithClassName {}

const IconFacebook: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'icon-facebook-root'}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4863 13.7124L15.8965 11.0171H13.2891V9.25928C13.2891 8.49756 13.6406 7.79443 14.8125 7.79443H16.0137V5.47998C16.0137 5.47998 14.9297 5.2749 13.9043 5.2749C11.7656 5.2749 10.3594 6.59326 10.3594 8.93701V11.0171H7.95703V13.7124H10.3594V20.2749H13.2891V13.7124H15.4863Z"
        fill="white"
      />
    </Root>
  )
}

export { IconFacebook }
