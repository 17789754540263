import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './IconCookie.styles'

interface Props extends PropsWithClassName {}

const IconCookie: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'icon-cookie-root'}
      width="24"
      height="24"
      viewBox="0 0 100.5 102.78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Cookie Einstellungen</title>
      <desc>Einstellungen für den Cookie Consent Manager</desc>
      <path
        fill="white"
        d="M0,52.52c0-13.63,5.34-26.39,15.03-35.92C24.65,7.14,37.37,2.06,50.83,2.28c.99,.02,1.94,.39,2.69,1.04,.64,.56,1.14,1.34,1.35,2.16,.93,3.71,3.43,6.84,6.86,8.61,1.89,.98,2.75,3.22,2,5.21-3.51,9.25,3.55,18.06,12.44,18.06l.75-.08,.7-.09c1.16-.13,2.31,.24,3.19,1,.87,.76,1.4,1.85,1.44,3.02,.21,6,4.41,11.15,10.21,12.52,4.48,1.06,7.24,5.67,6.18,10.28-5.31,22.83-25.31,38.78-48.64,38.78C22.43,102.78,0,80.24,0,52.53h0ZM47.81,10.7c-10.13,.52-19.59,4.67-26.91,11.87-8.08,7.95-12.53,18.58-12.53,29.95,0,23.09,18.67,41.88,41.62,41.88,19.41,0,36.06-13.28,40.48-32.3l-.05-.28c-8-1.87-14.16-8.26-16-16.17-11.17-.89-19.98-10.26-19.98-21.65,0-1.44,.15-2.88,.46-4.33-3.12-2.31-5.57-5.41-7.09-8.97h0Z"
      />
      <path
        fill="white"
        d="M59.88,61.98c-3.47,0-6.28-2.81-6.28-6.28s2.81-6.28,6.28-6.28,6.28,2.81,6.28,6.28-2.81,6.28-6.28,6.28"
      />
      <path
        fill="white"
        d="M37.69,44.15c-3.47,0-6.28-2.81-6.28-6.28s2.81-6.28,6.28-6.28,6.28,2.81,6.28,6.28-2.81,6.28-6.28,6.28"
      />
      <path
        fill="white"
        d="M20.82,68.26c-3.47,0-6.28-2.81-6.28-6.28s2.81-6.28,6.28-6.28,6.28,2.81,6.28,6.28-2.81,6.28-6.28,6.28"
      />
      <path
        fill="white"
        d="M40.56,65.09c-2.31,0-4.19-1.87-4.19-4.19s1.87-4.19,4.19-4.19,4.19,1.87,4.19,4.19-1.87,4.19-4.19,4.19"
      />
      <path
        fill="white"
        d="M33.5,84.77c-2.31,0-4.19-1.88-4.19-4.19s1.87-4.19,4.19-4.19,4.19,1.87,4.19,4.19-1.87,4.19-4.19,4.19"
      />
      <path
        fill="white"
        d="M96.32,44.15c-2.31,0-4.19-1.87-4.19-4.19s1.88-4.19,4.19-4.19,4.19,1.87,4.19,4.19-1.87,4.19-4.19,4.19"
      />
      <path
        fill="white"
        d="M83.75,8.37c-2.31,0-4.19-1.87-4.19-4.19s1.88-4.19,4.19-4.19,4.19,1.87,4.19,4.19-1.87,4.19-4.19,4.19"
      />
      <path
        fill="white"
        d="M77.47,27.4c-3.47,0-6.28-2.81-6.28-6.28s2.81-6.28,6.28-6.28,6.28,2.81,6.28,6.28-2.81,6.28-6.28,6.28"
      />
      <path
        fill="white"
        d="M50.25,81.84c-2.31,0-4.19-1.87-4.19-4.19s1.87-4.19,4.19-4.19,4.19,1.87,4.19,4.19-1.87,4.19-4.19,4.19"
      />
    </Root>
  )
}

export { IconCookie }
