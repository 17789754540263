import IconClose from './IconClose'
import IconCookie from './IconCookie'
import IconError from './IconError'
import IconFacebook from './IconFacebook'
import IconInstagram from './IconInstagram'
import IconLinkedin from './IconLinkedin'
import IconLinkExternal from './IconLinkExternal'
import IconLinkInternal from './IconLinkInternal'
import IconMail from './IconMail'
import IconNew from './IconNew'
import IconPhone from './IconPhone'
import IconPulldown from './IconPulldown'
import IconTwitter from './IconTwitter'
import IconZoom from './IconZoom'

export {
  IconClose,
  IconCookie,
  IconError,
  IconFacebook,
  IconInstagram,
  IconLinkExternal,
  IconLinkedin,
  IconLinkInternal,
  IconMail,
  IconNew,
  IconPhone,
  IconPulldown,
  IconTwitter,
  IconZoom,
}
