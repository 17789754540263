import styled, { css } from 'styled-components'

import { HeadlineImportances } from './Headline'

interface HeadlineProps {
  styleAs: HeadlineImportances
  importance: HeadlineImportances
}

export const h1Styles = css`
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
  hyphens: auto;
  color: ${(props) => props.theme.palette.default.primary};
  margin-bottom: ${(props) => props.theme.margin.half};
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.headline.one.mobile.fontSize};
  font-weight: ${(props) => props.theme.font.headline.one.mobile.fontWeight};
  line-height: ${(props) => props.theme.font.headline.one.mobile.lineHeight};
  letter-spacing: ${(props) =>
    props.theme.font.headline.one.mobile.letterSpacing};
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    font-family: ${(props) => props.theme.font.primaryFamily};
    font-size: ${(props) => props.theme.font.headline.one.desktop.fontSize};
    font-weight: ${(props) => props.theme.font.headline.one.desktop.fontWeight};
    line-height: ${(props) => props.theme.font.headline.one.desktop.lineHeight};
    letter-spacing: ${(props) =>
      props.theme.font.headline.one.desktop.letterSpacing};
  }
`

export const h2Styles = css`
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
  hyphens: auto;
  color: ${(props) => props.theme.palette.default.primary};
  margin-bottom: ${(props) => props.theme.margin.half};
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.headline.two.mobile.fontSize};
  font-weight: ${(props) => props.theme.font.headline.two.mobile.fontWeight};
  line-height: ${(props) => props.theme.font.headline.two.mobile.lineHeight};
  letter-spacing: ${(props) =>
    props.theme.font.headline.two.mobile.letterSpacing};
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    font-family: ${(props) => props.theme.font.primaryFamily};
    font-size: ${(props) => props.theme.font.headline.two.desktop.fontSize};
    font-weight: ${(props) => props.theme.font.headline.two.desktop.fontWeight};
    line-height: ${(props) => props.theme.font.headline.two.desktop.lineHeight};
    letter-spacing: ${(props) =>
      props.theme.font.headline.two.desktop.letterSpacing};
  }
`
export const h3Styles = css`
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
  hyphens: auto;
  color: ${(props) => props.theme.palette.default.black};
  margin-bottom: ${(props) => props.theme.margin.half};
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.headline.three.mobile.fontSize};
  font-weight: ${(props) => props.theme.font.headline.three.mobile.fontWeight};
  line-height: ${(props) => props.theme.font.headline.three.mobile.lineHeight};
  letter-spacing: ${(props) =>
    props.theme.font.headline.three.mobile.letterSpacing};
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    font-family: ${(props) => props.theme.font.primaryFamily};
    font-size: ${(props) => props.theme.font.headline.three.desktop.fontSize};
    font-weight: ${(props) =>
      props.theme.font.headline.three.desktop.fontWeight};
    line-height: ${(props) =>
      props.theme.font.headline.three.desktop.lineHeight};
    letter-spacing: ${(props) =>
      props.theme.font.headline.three.desktop.letterSpacing};
  }
`
export const h4Styles = css`
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
  hyphens: auto;
  color: ${(props) => props.theme.palette.default.black};
  margin-bottom: ${(props) => props.theme.margin.half};
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.headline.four.mobile.fontSize};
  font-weight: ${(props) => props.theme.font.headline.four.mobile.fontWeight};
  line-height: ${(props) => props.theme.font.headline.four.mobile.lineHeight};
  letter-spacing: ${(props) =>
    props.theme.font.headline.four.mobile.letterSpacing};
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    font-family: ${(props) => props.theme.font.primaryFamily};
    font-size: ${(props) => props.theme.font.headline.four.desktop.fontSize};
    font-weight: ${(props) =>
      props.theme.font.headline.four.desktop.fontWeight};
    line-height: ${(props) =>
      props.theme.font.headline.four.desktop.lineHeight};
    letter-spacing: ${(props) =>
      props.theme.font.headline.four.desktop.letterSpacing};
  }
`
export const h5Styles = css`
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
  hyphens: auto;
  margin-bottom: ${(props) => props.theme.margin.half};
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.headline.five.mobile.fontSize};
  font-weight: ${(props) => props.theme.font.headline.five.mobile.fontWeight};
  line-height: ${(props) => props.theme.font.headline.five.mobile.lineHeight};
  letter-spacing: ${(props) =>
    props.theme.font.headline.five.mobile.letterSpacing};
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    font-family: ${(props) => props.theme.font.primaryFamily};
    font-size: ${(props) => props.theme.font.headline.five.desktop.fontSize};
    font-weight: ${(props) =>
      props.theme.font.headline.five.desktop.fontWeight};
    line-height: ${(props) =>
      props.theme.font.headline.five.desktop.lineHeight};
    letter-spacing: ${(props) =>
      props.theme.font.headline.five.desktop.letterSpacing};
  }
`

export const getStyles = (as: HeadlineImportances | undefined) => {
  switch (as) {
    case HeadlineImportances.h1:
      return h1Styles
    case HeadlineImportances.h2:
      return h2Styles
    case HeadlineImportances.h3:
      return h3Styles
    case HeadlineImportances.h4:
      return h4Styles
    case HeadlineImportances.h5:
      return h5Styles
    default:
      return h1Styles
  }
}

export const H1 = styled.h1<HeadlineProps>`
  ${(props) => getStyles(props.styleAs ? props.styleAs : props.importance)}
`
export const H2 = styled.h2<HeadlineProps>`
  ${(props) => getStyles(props.styleAs ? props.styleAs : props.importance)}
`
export const H3 = styled.h3<HeadlineProps>`
  ${(props) => getStyles(props.styleAs ? props.styleAs : props.importance)}
`
export const H4 = styled.h4<HeadlineProps>`
  ${(props) => getStyles(props.styleAs ? props.styleAs : props.importance)}
`
export const H5 = styled.h5<HeadlineProps>`
  ${(props) => getStyles(props.styleAs ? props.styleAs : props.importance)}
`
