import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './IconTwitter.styles'

interface Props extends PropsWithClassName {}

const IconTwitter: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'icon-twitter-root'}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4473 9.01758C18.4473 9.16406 18.4473 9.28125 18.4473 9.42773C18.4473 13.5 15.3711 18.1582 9.7168 18.1582C7.95898 18.1582 6.34766 17.6602 5 16.7812C5.23438 16.8105 5.46875 16.8398 5.73242 16.8398C7.16797 16.8398 8.48633 16.3418 9.54102 15.5215C8.19336 15.4922 7.05078 14.6133 6.66992 13.3828C6.875 13.4121 7.05078 13.4414 7.25586 13.4414C7.51953 13.4414 7.8125 13.3828 8.04688 13.3242C6.64062 13.0312 5.58594 11.8008 5.58594 10.3066V10.2773C5.99609 10.5117 6.49414 10.6289 6.99219 10.6582C6.14258 10.1016 5.61523 9.16406 5.61523 8.10938C5.61523 7.52344 5.76172 6.99609 6.02539 6.55664C7.54883 8.40234 9.83398 9.63281 12.3828 9.7793C12.3242 9.54492 12.2949 9.31055 12.2949 9.07617C12.2949 7.37695 13.6719 6 15.3711 6C16.25 6 17.041 6.35156 17.627 6.9668C18.3008 6.82031 18.9746 6.55664 19.5605 6.20508C19.3262 6.9375 18.8574 7.52344 18.2129 7.9043C18.8281 7.8457 19.4434 7.66992 19.9707 7.43555C19.5605 8.05078 19.0332 8.57812 18.4473 9.01758Z"
        fill="white"
      />
    </Root>
  )
}

export { IconTwitter }
