import React from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './LoadingSpinner.styles'

interface Props extends PropsWithClassName {
  size?: LoadingSpinnerSize
  invertColors?: boolean
  isPositionAbsolute?: boolean
}

export enum LoadingSpinnerSize {
  default = '24px',
  large = '36px',
}

const LoadingSpinner = (props: Props): React.ReactElement => {
  const { size, invertColors, isPositionAbsolute } = props

  return (
    <Root
      size={size ?? LoadingSpinnerSize.default}
      invertColors={invertColors ?? false}
      isPositionAbsolute={isPositionAbsolute ?? false}
      className={props.className}
      data-testid={'LoadingSpinner-root'}
    ></Root>
  )
}

export { LoadingSpinner }
