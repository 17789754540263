import React from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './LogoText.styles'

interface Props extends PropsWithClassName {}

const LogoText = (props: Props): React.ReactElement => {
  return (
    <Root
      width="184"
      height="38"
      viewBox="0 0 184 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      data-testid={'LogoText-root'}
      aria-label="QOSSMIC Text Logo"
    >
      <path
        d="M45.4996 0.013916C37.4293 0.013916 31.8447 6.14233 31.8447 14.919C31.8447 23.6957 37.4877 29.8125 45.4996 29.8125C53.5116 29.8125 59.1545 23.5459 59.1545 14.9463C59.1545 6.34665 53.3919 0.013916 45.4996 0.013916ZM45.4996 26.6398C39.4919 26.6398 35.2996 21.8092 35.2996 14.921C35.2996 8.03271 39.4919 3.18855 45.4996 3.18855C51.5074 3.18855 55.7153 8.11638 55.7153 14.921C55.7153 21.7256 51.3576 26.6398 45.4996 26.6398Z"
        fill="#F2F2F2"
      />
      <path
        d="M13.6549 0.0144043C5.70811 0.0144043 0 6.28098 0 14.9215C0 23.5619 5.77621 29.8149 13.723 29.8149C21.6698 29.8149 27.3779 23.5483 27.3779 14.9195C27.3779 6.29071 21.6017 0.0144043 13.6549 0.0144043ZM13.6549 26.6403C7.63351 26.6403 3.43927 21.8097 3.43927 14.9215C3.43927 8.0332 7.63157 3.18904 13.6549 3.18904C19.6782 3.18904 23.856 8.11687 23.856 14.9195C23.856 21.7222 19.5537 26.6403 13.6549 26.6403Z"
        fill="#F2F2F2"
      />
      <path
        d="M13.3813 34.0248L26.4379 37.752L27.298 34.591L14.2609 31.002L13.3813 34.0248Z"
        fill="#F2F2F2"
      />
      <path
        d="M152.47 0.470459H149.083V29.3467H152.47V0.470459Z"
        fill="#F2F2F2"
      />
      <path
        d="M128.437 22.6095L118.728 0.469727H114.632V29.3557H117.842V6.29751L126.65 25.967L126.718 26.1188H130.2L138.957 6.39481V29.3557H142.167V0.469727H138.112L128.437 22.6095Z"
        fill="#F2F2F2"
      />
      <path
        d="M180.586 20.4679V20.6332C180.028 22.4464 178.883 24.0223 177.33 25.1121C175.777 26.2019 173.906 26.7436 172.011 26.6517C166.139 26.6517 162.029 21.8893 162.029 15.0575C162.029 8.22564 166.125 3.15965 172.05 3.15965C173.917 3.07613 175.754 3.65705 177.234 4.79928C178.714 5.94151 179.741 7.57115 180.134 9.39896V9.57799H183.561V9.28611C182.51 3.57216 178.099 0.0239258 171.984 0.0239258C164.187 0.0239258 158.752 6.20779 158.752 15.0555C158.752 23.9032 164.214 29.7835 172.024 29.7835C178.057 29.7835 182.867 26.1536 184 20.7568V20.4649L180.586 20.4679Z"
        fill="#F2F2F2"
      />
      <path
        d="M73.3523 3.02285C74.0715 2.9794 74.7922 3.07815 75.4733 3.31345C76.1543 3.54876 76.7823 3.91601 77.3213 4.39419C77.8603 4.87238 78.2997 5.45213 78.6145 6.10027C78.9293 6.74842 79.1132 7.45226 79.1557 8.17154V8.3924H82.4053V8.12971C82.1455 3.27095 78.4864 0 73.2978 0C68.1092 0 64.3538 3.27095 64.3538 7.95068C64.3538 13.2648 69.0782 14.8526 71.6185 15.6669L73.0662 16.1222C76.057 17.0747 78.9787 18.3307 78.9787 21.9889C78.9787 26.3233 74.8827 26.7922 73.2161 26.7922C69.5015 26.7922 67.2764 24.8872 66.7705 21.2709V21.0501H63.5073V21.3264C63.9578 26.7095 67.4963 29.8151 73.2161 29.8151C78.9359 29.8151 82.3615 26.7786 82.3615 21.8508C82.3615 15.8051 76.9949 14.0674 74.6871 13.3066L73.325 12.9067C70.813 12.0924 67.6821 11.0708 67.6821 7.81351C67.6724 5.01053 70.0755 3.02285 73.3523 3.02285Z"
        fill="#F2F2F2"
      />
      <path
        d="M98.2458 3.02285C99.6961 2.93884 101.12 3.43388 102.206 4.39924C103.292 5.3646 103.95 6.72136 104.036 8.17154V8.3924H107.285V8.12971C107.025 3.27095 103.366 0 98.1913 0C93.0163 0 89.2473 3.27095 89.2473 7.95068C89.2473 13.2648 93.9581 14.8526 96.4984 15.6669L97.9461 16.1222C100.95 17.0747 103.859 18.3307 103.859 21.9889C103.859 26.3233 99.8443 26.7922 98.1096 26.7922C94.395 26.7922 92.1699 24.8872 91.664 21.2709V21.0501H88.3872V21.3264C88.8377 26.7095 92.3762 29.8151 98.096 29.8151C103.816 29.8151 107.19 26.7786 107.19 21.9062C107.19 15.8605 101.823 14.1229 99.5291 13.3621L98.167 12.9622C95.6685 12.1479 92.5416 11.1263 92.5416 7.86896C92.561 5.01053 94.896 3.02285 98.2458 3.02285Z"
        fill="#F2F2F2"
      />
    </Root>
  )
}

export { LogoText }
