import React from 'react'

import { ButtonModifier, ButtonType } from '@Components/atoms/Button/Button'
import { IconCookie } from '@Components/atoms/Icons'
import { useGlobalContext } from '@Components/context/GlobalContext'
import { PropsWithClassName } from '@Components/helper'

import { Root } from './CookieButton.styles'

interface Props extends PropsWithClassName {}

const CookieButton = (props: Props): React.ReactElement => {
  const { klaro } = useGlobalContext()

  return (
    <Root
      className={props.className}
      dataTestId={'CookieButton-root'}
      modifier={ButtonModifier.primary}
      type={ButtonType.button}
      onClick={klaro.open}
      icon={<IconCookie />}
    ></Root>
  )
}

export { CookieButton }
